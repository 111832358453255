import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import SelectSalePayment from '../../components/select-sale-payment/select-sale-payment';
import SelectSaleUnit from '../../components/select-sale-unit/select-sale-unit';
import SaleReservationForm from './SaleReservationForm';
import SuccessStep from './SuccessStep';
import {
  CreateSaleProvider,
  CreateSaleStepsEnum,
  useCreateSaleContext,
} from './create-sale-context';
import styles from './create-sale.module.scss';

function CreateSale() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { customerId, pipelineId } = useParams<{
    customerId: string;
    pipelineId: string;
  }>();

  const { step, goBack } = useCreateSaleContext();

  const goBackHandler = () => {
    if (step === CreateSaleStepsEnum.Unit) {
      navigate(ROUTES['CustomerPipelines'].getPath?.(customerId));
    } else {
      goBack();
    }
  };
  const isUnitStep = step === CreateSaleStepsEnum.Unit;
  const isPaymentStep = step === CreateSaleStepsEnum.Payment;
  const isReservationStep = step === CreateSaleStepsEnum.Reservation;
  const isSuccessStep = step === CreateSaleStepsEnum.Success;
  const showCreateFormHeader = !isSuccessStep;

  return (
    <div className={styles['wrapper']}>
      {showCreateFormHeader && (
        <>
          {!isReservationStep && (
            <button onClick={goBackHandler} className={styles['back-anchor']}>
              <img src={arrowIcon} alt="" role="presentation" />
              <span className="anchor">{t('back')}</span>
            </button>
          )}
          <h1 className={styles['title']}>
            {t('createSale')} ({step}/3)
          </h1>
        </>
      )}

      {isUnitStep && <SelectSaleUnit />}
      {isPaymentStep && (
        <SelectSalePayment
          customerSourceId={customerId}
          pipelineSourceId={pipelineId}
        />
      )}
      {isReservationStep && <SaleReservationForm />}
      {isSuccessStep && <SuccessStep />}
    </div>
  );
}
const CreateSalePage = () => {
  return (
    <CreateSaleProvider>
      <CreateSale />
    </CreateSaleProvider>
  );
};

export default CreateSalePage;

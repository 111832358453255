import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObjectionValues } from '../../utils/deal.utils';
import {
  UpdatePipeline,
  UpdatePipelineInputInterface,
} from '../../definitions/interfaces/deals.interface';
import { PipelineDetails } from '../../definitions/interfaces/lead-stages.interface';

export const useObjectionSchema = () => {
  const { t } = useTranslation();

  const meetingSchema = yup.object({
    objection: yup.bool().label(t('objection')),
    objectionNotes: yup
      .string()
      .label(t('objectionNotes'))
      .when('objection', (objection, schema) => {
        return objection ? schema.required() : schema.optional();
      })
      .transform((value) => {
        return value ?? undefined;
      }),
    objectionReason: yup
      .object()
      .label(t('objectionReason'))
      .when('objection', (objection, schema) => {
        return objection ? schema.required() : schema.optional();
      })
      .transform((value) => {
        return value ?? undefined;
      }),
    competitor: yup
      .object()
      .label(t('competitor'))
      .when('objectionReason', (objectionReason, schema) => {
        const isCompetition =
          objectionReason?.value === ObjectionValues.COMPETITION;
        return isCompetition ? schema.required() : schema.optional();
      })
      .transform((value) => {
        return value ?? undefined;
      }),
  });

  return meetingSchema;
};
interface UpdataPiplineArgs {
  values: UpdatePipelineInputInterface;
  pipeline: PipelineDetails;
  leadId?: string;
  customerId?: string;
}
export function buildUpdatePipelinePayload({
  pipeline,
  values,
  leadId,
  customerId,
}: UpdataPiplineArgs): UpdatePipeline {
  return {
    objections_handled: !!values.objection,
    lead_source_id: leadId ?? '',
    customer_source_id: customerId ?? '',
    destination_slug: pipeline?.destination_slug,
    feedback_id: pipeline?.feedback_id,
    sales_comment: '',
    objection_id:
      values.objection && values.objectionReason
        ? +values.objectionReason?.value
        : null,
    objection_notes: values.objection ? values.objectionNotes : '',
    competitor_source_id: values.competitor?.value
      ? String(values.competitor?.value)
      : '',
  };
}

import { User as UserApi } from '../api/user';
import { Payload, UserLoginResponse } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';

export class User {
  public static getUserData(): Promise<Payload<UserLoginResponse>> {
    return UserApi.getUserData()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

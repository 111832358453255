import { useEffect, useState } from 'react';
import styles from './date-filter-popup.module.scss';
import ArrowIcon from '../../assets/icons/dropdown-grey.svg';
import { useTranslation } from 'react-i18next';
import { DateOptions } from '../../definitions/interfaces/deals.interface';

interface DateFilterPopupProps {
  onFilterChange: (startDate: Date | null, endDate: Date | null) => void;
  reset: boolean;
}
export function DateFilterPopup(props: DateFilterPopupProps) {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string>(DateOptions.ALL);

  useEffect(() => {
    setActiveFilter(DateOptions.ALL);
  }, [props.reset]);

  const dateOptions = [
    { label: 'All', value: DateOptions.ALL },
    { label: 'Today', value: DateOptions.TODAY },
    { label: 'Last Week', value: DateOptions.LAST_WEEK },
    { label: 'This Month', value: DateOptions.THIS_MONTH },
    { label: 'This Year', value: DateOptions.THIS_YEAR },
  ];

  const handleFilterSelection = (filterType: string) => {
    const now = new Date();
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    switch (filterType) {
      case DateOptions.ALL:
        startDate = null;
        endDate = null;
        break;
      case DateOptions.TODAY:
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));

        break;
      case DateOptions.LAST_WEEK:
        endDate = new Date(now);
        startDate = new Date(now);
        startDate.setDate(endDate.getDate() - 6);
        break;
      case DateOptions.THIS_MONTH:
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case DateOptions.THIS_YEAR:
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        break;
      default:
        break;
    }

    setActiveFilter(filterType);
    props.onFilterChange(startDate, endDate);
  };
  return (
    <>
      <button onClick={() => setShowFilter(!showFilter)}>
        <img src={ArrowIcon} alt="" />
      </button>
      {showFilter && (
        <div className={styles['filters']}>
          <p>{t('filterBy')}</p>
          <ul className={styles['options']}>
            {dateOptions.map((option, index) => (
              <div key={option.value} className={styles['option']}>
                <li
                  className={`${styles['option']} ${
                    activeFilter === option.value ? styles['active'] : ''
                  }`}
                  onClick={() => handleFilterSelection(option.value)}
                  onKeyDown={() => handleFilterSelection(option.value)}
                >
                  {option.label}
                </li>
                {index !== dateOptions.length - 1 && <hr />}
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
export default DateFilterPopup;

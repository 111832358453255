import { Unit } from '../../utils/unit';
import { ROUTES } from '../../api/routes';
import { ComparePropertiesPage as ComparePropertiesPageUi } from '@orascom/broker-sales-man-common-components';

const ComparePropertiesPage = () => {
  return (
    <ComparePropertiesPageUi
      getCompareUnits={Unit.getSalesManCompareUnits}
      getUnitPaymentTerms={Unit.getSalesManUnitPaymentTerms}
      primaryUnitsRoute={ROUTES['PrimaryUnits'].path}
      unitDetailsGetPath={ROUTES['UnitDetails'].getPath}
      unitDealGetPath={ROUTES['UnitDeal'].getPath}
      downloadOmanSalesOffer={Unit.downloadSalesManOmanSalesOffer}
      salesOffer
    />
  );
};

export default ComparePropertiesPage;

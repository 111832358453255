import { SelectDropdown } from '@orascom/common-components';
import styles from './pipeline-type.module.scss';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import { LeadDiscoveryInput } from '../../definitions/interfaces/lead-stages.interface';

export function PipelineType() {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } =
    useFormikContext<LeadDiscoveryInput>();

  const unitTypesOptions = [
    { label: t('singleUnit'), value: 1 },
    { label: t('multipleUnits'), value: 2 },
  ];

  return (
    <div className={styles['pipeline-type']}>
      <p className={styles['title']}>{t('pipelineType')}</p>

      <div className={styles['pipeline-input']}>
        <label htmlFor="pipelineType">{t('type')}</label>
        <SelectDropdown
          name="pipelineType"
          options={unitTypesOptions}
          placeholder={t('selectPlaceholder')}
          className={`${styles['select']} ${
            errors?.pipelineType && touched?.pipelineType ? 'error' : ''
          }`}
          onChange={(value) => {
            setFieldValue('pipelineType', value?.value);
          }}
          selectedOption={unitTypesOptions.find((option) => {
            return option.value === values.pipelineType;
          })}
        />
        <ErrorMessage
          name="pipelineType"
          component="div"
          className="form__error"
        />
      </div>
    </div>
  );
}

export default PipelineType;

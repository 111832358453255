import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './my-sales.module.scss';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { Sales as SalesInterface } from '../../definitions/interfaces/sales.interface';
import { Sales } from '../../utils/sale';
import { Loader } from '@orascom/common-components';
import { format } from 'date-fns';

export function MySales() {
  const { t } = useTranslation();

  const [salesDate, setSalesDate] = useState<SalesInterface[]>([]);
  const tabs = { all: 1, pending: 2, reserved: 3, contracted: 4 };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salesDate.slice(indexOfFirstItem, indexOfLastItem);
  const [tableLoading, setTableLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    Sales.getSales(activeTab)
      .then((res) => {
        setSalesDate(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [activeTab]);

  const handleTabBtn = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setCurrentPage(1);
      setTableLoading(true);
    }
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('mySales')}</h1>
      <div className={styles['tabs']}>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.all);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.all && styles['tab--active']
          }`}
        >
          {t('allSales')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.pending);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.pending && styles['tab--active']
          }`}
        >
          {t('pendingSales')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.reserved);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.reserved && styles['tab--active']
          }`}
        >
          {t('reservedSales')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.contracted);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.contracted && styles['tab--active']
          }`}
        >
          {t('contractedSales')}
        </button>
      </div>
      <div
        style={{
          overflow: 'auto',
          display: 'grid',
          marginBlockStart: '20px',
        }}
        className={`analytics-table ${
          tableLoading ? '' : styles['scroll-bar']
        }`}
      >
        {tableLoading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length ? (
          <table>
            <thead>
              <tr
                style={{
                  gridTemplateColumns: `repeat(8, minmax(150px, 1fr))`,
                }}
              >
                <th className="manager-th">{t('unitName')}</th>
                <th className="manager-th">{t('customerName')}</th>
                <th className="manager-th">{t('status')}</th>
                <th className="manager-th">{t('reservationDate')}</th>
                <th className="manager-th">{t('unitPrice')}</th>
                <th className="manager-th">{t('sellingPrice')}</th>
                <th className="manager-th">{t('paymentTerm')}</th>
                <th className="manager-th">{t('saleSource')}</th>
              </tr>
            </thead>

            <tbody>
              {currentItems.map((item) => {
                const date = new Date(item.reservation_date);
                const formattedDate = format(date, 'dd MMMM yyyy');
                return (
                  <tr
                    className={`sales-man-tr ${styles['table-columns']}`}
                    key={uuidv4()}
                  >
                    <td className="manager-td--name">{item.unit_name}</td>
                    <td className="manager-td--name">{item.customer_name}</td>
                    <td className="sales-man-td">{item.sale_status}</td>
                    <td className="sales-man-td">
                      {item.reservation_date ? formattedDate : null}
                    </td>
                    <td className="sales-man-td">
                      {item.unit_price_with_currency}
                    </td>
                    <td className="sales-man-td">{item.price}</td>
                    <td className="sales-man-td">{item.payment_term_name}</td>
                    <td className="manager-td--name">{item.sale_source}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>{t('noSalesToDisplay')}</p>
        )}
      </div>
      {!tableLoading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > salesDate.length
                ? salesDate.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{salesDate.length} </span>
            {t('sales')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={salesDate.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MySales;

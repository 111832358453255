import { useEffect, useState } from 'react';
import styles from './filter-popup.module.scss';
import ArrowIcon from '../../assets/icons/dropdown-grey.svg';
import { useTranslation } from 'react-i18next';

interface FilterPopupProps {
  column: string;
  onFilterChange: (column: string, selectedValues: string[]) => void;
  options: {
    label: string | number | undefined | null;
    value: string | number | undefined | null;
  }[];
  selectedValues: string[];
}

export function FilterPopup(props: FilterPopupProps) {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOptions(props.selectedValues);
  }, [props.selectedValues]);

  const handleCheckboxChange = (value: string) => {
    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    setSelectedOptions(updatedOptions);
    props.onFilterChange(props.column, updatedOptions);
  };

  return (
    <>
      <button onClick={() => setShowFilter(!showFilter)}>
        {' '}
        <img src={ArrowIcon} alt="" />
      </button>
      {showFilter && (
        <div className={styles['filters']}>
          <p>{t('filterBy')}</p>
          <div className={styles['options']}>
            {props.options
              .filter((option) => option.value !== null)
              .map((option, index) => (
                <div key={String(option.value)} className={styles['option']}>
                  <div className={styles['checkbox-wrapper']}>
                    <input
                      type="checkbox"
                      value={String(option.value)}
                      checked={selectedOptions.includes(String(option.value))}
                      onChange={() =>
                        handleCheckboxChange(String(option.value))
                      }
                    />
                    <label>{option.label}</label>
                  </div>
                  {index !== props.options.length - 1 && <hr />}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default FilterPopup;

import { useTranslation } from 'react-i18next';
import styles from './qualify-customer.module.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import {
  Loader,
  LoadingButton,
  SelectDropdown,
} from '@orascom/common-components';
import {
  GenderEnum,
  MaritalStatusEnum,
  QualifyCustomerInput,
  QualifyCustomer as QualifyCustomerInterface,
} from '../../definitions/interfaces/qualify-customer';
import { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import NationalIdIcon from '../../assets/icons/identity-card.svg?react';
import PassportIcon from '../../assets/icons/passport.svg?react';
import UploadIcon from '../../assets/icons/reservation-upload.svg?react';
import qualifyImg from '../../assets/images/qualify-customer.webp';
import { QualifyCustomer as QualifyCustomerUtils } from '../../utils/qualify-customer';
import { OptionValue } from '@orascom/api-interfaces';
import { toast } from 'react-toastify';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';

type Value = Date | null;

export function QualifyCustomer() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [pendingQualify, setPendingQualify] = useState(false);
  const navigate = useNavigate();

  const { destination, leadId } = useParams();
  const location = useLocation();
  const pipelineSourceId = location.state?.pipeline_source_id;

  const [nationalities, setNationalities] = useState<OptionValue[]>();
  const [cities, setCities] = useState<OptionValue[]>();
  const [countries, setCountries] = useState<OptionValue[]>();
  const [occupations, setOccupations] = useState<OptionValue[]>();

  const [nationalIdFrontImageText, setNationalIdFrontImageText] = useState('');
  const [nationalIdBackImageText, setNationalIdBackImageText] = useState('');
  const [passportImageText, setPassportImageText] = useState('');

  const [selectedDateBirth, setSelectedDateBirth] = useState<Value>();
  const dialogRefBirth = useRef<HTMLDialogElement | null>(null);
  const [selectedDateExpiry, setSelectedDateExpiry] = useState<Value>();
  const dialogRefExpiry = useRef<HTMLDialogElement | null>(null);
  const [selectedDateIssue, setSelectedDateIssue] = useState<Value>();
  const dialogRefIssue = useRef<HTMLDialogElement | null>(null);

  const toggleDialogBirth = () => {
    if (dialogRefBirth.current) {
      if (dialogRefBirth.current.open) {
        dialogRefBirth.current.close();
      } else {
        dialogRefBirth.current.show();
      }
    }
  };
  const toggleDialogExpiry = () => {
    if (dialogRefExpiry.current) {
      if (dialogRefExpiry.current.open) {
        dialogRefExpiry.current.close();
      } else {
        dialogRefExpiry.current.show();
      }
    }
  };
  const toggleDialogIssue = () => {
    if (dialogRefIssue.current) {
      if (dialogRefIssue.current.open) {
        dialogRefIssue.current.close();
      } else {
        dialogRefIssue.current.show();
      }
    }
  };

  const schema = Yup.object().shape({
    originNationalityId: Yup.object().nullable().required(t('fieldIsRequired')),
    nationality: Yup.object().nullable().required(t('fieldIsRequired')),
    country: Yup.object().nullable().required(t('fieldIsRequired')),
    city: Yup.object().nullable().required(t('fieldIsRequired')),
    email: Yup.string()
      .email(t('InvalidEmail'))
      .nullable()
      .required(t('fieldIsRequired')),
    issuedFrom: Yup.string().nullable().required(t('fieldIsRequired')),
    arabicIssueFrom: Yup.string().nullable().required(t('fieldIsRequired')),
    arabicAddress: Yup.string().nullable().required(t('fieldIsRequired')),
    district: Yup.string().nullable().required(t('fieldIsRequired')),
    otherDistrictName: Yup.string().nullable().required(t('fieldIsRequired')),
    address: Yup.string().nullable().required(t('fieldIsRequired')),
    occupation: Yup.object().nullable().required(t('fieldIsRequired')),
    maritalStatus: Yup.string().required(t('fieldIsRequired')),
    gender: Yup.string().required(t('fieldIsRequired')),
    dateOfBirth: Yup.string().nullable().required(t('fieldIsRequired')),
    identificationForm: Yup.string().required(t('fieldIsRequired')),
    nationalIdNumber: Yup.string().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    expiryDate: Yup.string().nullable().required(t('fieldIsRequired')),

    issueDate: Yup.string().nullable().required(t('fieldIsRequired')),

    nationalIdFrontImage: Yup.mixed().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    nationalIdBackImage: Yup.mixed().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    passportNumber: Yup.string().when('identificationForm', {
      is: 'passport',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    passportImage: Yup.mixed().when('identificationForm', {
      is: 'passport',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      QualifyCustomerUtils.getCustomerNationalities(),
      QualifyCustomerUtils.getCustomerCountries(),
      QualifyCustomerUtils.getCustomerCities(),
      QualifyCustomerUtils.getCustomerOccupations(),
    ])
      .then((res) => {
        setNationalities(res[0]);
        setCountries(res[1]);
        setCities(res[2]);
        setOccupations(res[3]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleSubmitForm = async (values: QualifyCustomerInput) => {
    const mappedData: QualifyCustomerInterface = {
      issued_from: values.issuedFrom,
      arabic_issue_from: values.arabicIssueFrom,
      arabic_address: values.arabicAddress,
      email: values.email,
      destination_slug: destination ?? '',
      lead_source_id: leadId ?? '',
      origin_nationality_id: values.originNationalityId?.value.toString() ?? '',
      nationality_id: values.nationality?.value.toString() ?? '',
      country_id: values.country?.value.toString() ?? '',
      city_id: values.city?.value.toString() ?? '',
      district_id: '',
      district_name: values.district,
      other_district_name: values.otherDistrictName,
      address: values.address,
      occupation_code: values.occupation?.value.toString() ?? '',
      other_occupation_name: '',
      date_of_birth: values.dateOfBirth,
      marital_status:
        values.maritalStatus === MaritalStatusEnum.MARRIED
          ? Number(MaritalStatusEnum.MARRIED)
          : Number(MaritalStatusEnum.SINGLE),
      gender:
        values.gender === GenderEnum.MALE
          ? Number(GenderEnum.MALE)
          : Number(GenderEnum.FEMALE),
      passport_number:
        values.identificationForm === 'passport' ? values.passportNumber : '',
      national_id_number:
        values.identificationForm === 'nationalId'
          ? values.nationalIdNumber
          : '',
      expiry_date: values.expiryDate,
      issue_date: values.issueDate,
    };

    try {
      setPendingQualify(true);

      const res = await QualifyCustomerUtils.qualifyCustomer(mappedData);

      const uploadAttachments = async () => {
        if (values.identificationForm === 'passport' && values.passportImage) {
          await QualifyCustomerUtils.attachementCustomer(
            res.customer_source_id,
            destination ?? '',
            values.passportImage
          );
        } else if (values.nationalIdFrontImage && values.nationalIdBackImage) {
          await Promise.all([
            QualifyCustomerUtils.attachementCustomer(
              res.customer_source_id,
              destination ?? '',
              values.nationalIdFrontImage
            ),
            QualifyCustomerUtils.attachementCustomer(
              res.customer_source_id,
              destination ?? '',
              values.nationalIdBackImage
            ),
          ]);
        }
      };

      await uploadAttachments();

      toast.success('Customer Qualified');
      navigate(ROUTES['CustomerPipelines'].getPath?.(res.customer_source_id), {
        state: {
          pipelineSourceId,
        },
      });
    } catch (error: any) {
      toast.error(String(error.message));
    } finally {
      setPendingQualify(false);
    }
  };

  if (loading) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles['wrapper']}>
      {leadId && (
        <Link
          to={ROUTES['LeadPipelines'].getPath?.(leadId)}
          className={styles['back-anchor']}
        >
          <img src={arrowIcon} alt="" role="presentation" />
          <span className="anchor">{t('back')}</span>
        </Link>
      )}
      <h1 className={styles['title']}>{t('qualifyLeadToCustomer')}</h1>
      <p>{t('leadInformation')}</p>
      <div className={styles['grid']}>
        <Formik
          initialValues={{
            issuedFrom: null,
            arabicIssueFrom: null,
            arabicAddress: null,
            email: null,
            originNationalityId: null,
            nationality: null,
            country: null,
            city: null,
            district: '',
            address: '',
            occupation: null,
            dateOfBirth: null,
            otherDistrictName: null,
            maritalStatus: MaritalStatusEnum.SINGLE,
            gender: GenderEnum.MALE,
            identificationForm: 'nationalId',
            nationalIdNumber: '',
            expiryDate: null,
            issueDate: null,
            nationalIdFrontImage: undefined,
            nationalIdBackImage: undefined,
            passportNumber: '',
            passportImage: undefined,
          }}
          validationSchema={schema}
          onSubmit={handleSubmitForm}
        >
          {({ errors, touched, setFieldValue, values, isValid }) => (
            <Form>
              <div className="input-wrapper">
                <label htmlFor="email">{t('email')}</label>
                <Field
                  name="email"
                  type="email"
                  className={errors.email && touched.email ? 'error' : ''}
                  value={values.email}
                  placeholder="example@example.com"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="originNationalityId">
                  {t('originOfNationality')}
                </label>
                <SelectDropdown
                  name="originNationalityId"
                  className={`${styles['select']} ${
                    errors.originNationalityId && touched.originNationalityId
                      ? 'error'
                      : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('originNationalityId', option);
                  }}
                  options={nationalities}
                  selectedOption={values.originNationalityId}
                />
                <ErrorMessage
                  name="originNationalityId"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="nationality">{t('nationality')}</label>
                <SelectDropdown
                  name="nationality"
                  className={`${styles['select']} ${
                    errors.nationality && touched.nationality ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('nationality', option);
                  }}
                  options={nationalities}
                  selectedOption={values.nationality}
                />
                <ErrorMessage
                  name="nationality"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="country">{t('country')}</label>
                <SelectDropdown
                  name="country"
                  className={`${styles['select']} ${
                    errors.country && touched.country ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('country', option);
                  }}
                  options={countries}
                  selectedOption={values.country}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="city">{t('city')}</label>
                <SelectDropdown
                  name="city"
                  className={`${styles['select']} ${
                    errors.city && touched.city ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('city', option);
                  }}
                  options={cities}
                  selectedOption={values.city}
                />
                <ErrorMessage
                  name="nationality"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="district">{t('district')}</label>
                <Field
                  name="district"
                  type="text"
                  className={errors.district && touched.district ? 'error' : ''}
                  value={values.district}
                  placeholder="District"
                />
                <ErrorMessage
                  name="district"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="otherDistrictName">{t('otherDistrict')}</label>
                <Field
                  name="otherDistrictName"
                  type="text"
                  className={
                    errors.otherDistrictName && touched.otherDistrictName
                      ? 'error'
                      : ''
                  }
                  value={values.otherDistrictName}
                  placeholder="otherDistrictName"
                />
                <ErrorMessage
                  name="otherDistrictName"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="address">{t('address')}</label>
                <Field
                  name="address"
                  type="text"
                  className={errors.address && touched.address ? 'error' : ''}
                  value={values.address}
                  placeholder="Address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="arabicAddress">{t('arabicAdress')}</label>
                <Field
                  name="arabicAddress"
                  type="text"
                  className={
                    errors.arabicAddress && touched.arabicAddress ? 'error' : ''
                  }
                  value={values.arabicAddress}
                  placeholder="العنوان بالعربي"
                />
                <ErrorMessage
                  name="arabicAddress"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="issuedFrom">{t('issuedFrom')}</label>
                <Field
                  name="issuedFrom"
                  type="text"
                  className={
                    errors.issuedFrom && touched.issuedFrom ? 'error' : ''
                  }
                  value={values.issuedFrom}
                  placeholder="Location"
                />
                <ErrorMessage
                  name="issuedFrom"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="arabicIssueFrom">{t('arabicIssuedFrom')}</label>
                <Field
                  name="arabicIssueFrom"
                  type="text"
                  className={
                    errors.arabicIssueFrom && touched.arabicIssueFrom
                      ? 'error'
                      : ''
                  }
                  value={values.arabicIssueFrom}
                  placeholder="العنوان بالعربي"
                />
                <ErrorMessage
                  name="arabicIssueFrom"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="occupation">{t('occupation')}</label>
                <SelectDropdown
                  name="occupation"
                  className={`${styles['select']} ${
                    errors.occupation && touched.occupation ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('occupation', option);
                  }}
                  options={occupations}
                  selectedOption={values.occupation}
                />
                <ErrorMessage
                  name="occupation"
                  component="div"
                  className="form__error"
                />
              </div>

              <div>
                <label htmlFor="dateOfBirth">{t('dateOfBirth')}</label>
                <div className="calendar-modal-wrapper">
                  <button
                    type="button"
                    onClick={toggleDialogBirth}
                    className="btn-outline-full"
                  >
                    <p>
                      {selectedDateBirth
                        ? format(new Date(selectedDateBirth), 'yyyy-MM-dd')
                        : t('selectDate')}
                    </p>
                    <img
                      style={{ height: 'auto', rotate: '-90deg' }}
                      src={ChevronLeft}
                      alt=""
                      role="presentation"
                    />
                  </button>
                  <dialog ref={dialogRefBirth} className="calendar-modal">
                    <Calendar
                      maxDate={new Date()}
                      value={selectedDateBirth}
                      onChange={(date) => {
                        setSelectedDateBirth(date as Value);
                        const formattedDate = format(
                          new Date(date as Date),
                          'yyyy-MM-dd'
                        );
                        setFieldValue(
                          'dateOfBirth',
                          Math.floor(new Date(formattedDate).getTime() / 1000)
                        );
                        toggleDialogBirth();
                      }}
                      defaultView="decade"
                      prevLabel={
                        <img
                          style={{ height: 'auto' }}
                          src={LeftArrowIcon}
                          alt=""
                          role="presentation"
                        />
                      }
                      nextLabel={
                        <img
                          style={{ height: 'auto' }}
                          src={RightArrowIcon}
                          alt=""
                          role="presentation"
                        />
                      }
                    />
                  </dialog>
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="form__error"
                  />
                </div>
              </div>

              <div role="group" className="input-wrapper radio-buttons--small">
                <label htmlFor="maritalStatus">{t('maritalStatus')}</label>
                <div>
                  <label>
                    <div>
                      <p>{t('single')}</p>
                      <Field
                        type="radio"
                        name="maritalStatus"
                        value={MaritalStatusEnum.SINGLE}
                      />
                    </div>
                  </label>
                  <label>
                    <div>
                      <p>{t('married')}</p>
                      <Field
                        type="radio"
                        name="maritalStatus"
                        value={MaritalStatusEnum.MARRIED}
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage
                  name="maritalStatus"
                  component="div"
                  className="form__error"
                />
              </div>

              <div role="group" className="input-wrapper radio-buttons--small">
                <label htmlFor="gender">{t('gender')}</label>
                <div>
                  <label>
                    <div>
                      <p>{t('male')}</p>
                      <Field
                        type="radio"
                        name="gender"
                        value={GenderEnum.MALE}
                      />
                    </div>
                  </label>
                  <label>
                    <div>
                      <p>{t('female')}</p>
                      <Field
                        type="radio"
                        name="gender"
                        value={GenderEnum.FEMALE}
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="form__error"
                />
              </div>

              <div role="group" className="input-wrapper radio-buttons--small">
                <label htmlFor="personalIdentificationForm">
                  {' '}
                  {t('personalIdentificationForm')}
                </label>
                <div>
                  <label>
                    <div>
                      <p>
                        <NationalIdIcon />
                        {t('nationalId')}
                      </p>
                      <Field
                        type="radio"
                        name={'identificationForm'}
                        value="nationalId"
                      />
                    </div>
                  </label>

                  <label>
                    <div>
                      <p>
                        <PassportIcon />
                        {t('passport')}
                      </p>
                      <Field
                        type="radio"
                        name={'identificationForm'}
                        value="passport"
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage
                  name="identificationForm"
                  component="div"
                  className="form__error"
                />
              </div>
              {values.identificationForm === 'nationalId' && (
                <div className="input-wrapper">
                  <label htmlFor="nationalIdNumber">
                    {t('nationalIdNumber')}
                  </label>
                  <Field
                    name="nationalIdNumber"
                    type="text"
                    className={
                      errors.nationalIdNumber && touched.nationalIdNumber
                        ? 'error'
                        : ''
                    }
                  />
                  <ErrorMessage
                    name="nationalIdNumber"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}
              {values.identificationForm === 'passport' && (
                <div className="input-wrapper">
                  <label htmlFor="passportNumber">{t('passportNumber')}</label>
                  <Field
                    name="passportNumber"
                    type="text"
                    className={
                      errors.passportNumber && touched.passportNumber
                        ? 'error'
                        : ''
                    }
                  />
                  <ErrorMessage
                    name="passportNumber"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}
              <div className="calendar-wrapper">
                <div>
                  <label htmlFor="expiryDate">{t('idExpiryDate')}</label>
                  <div className="calendar-modal-wrapper">
                    <button
                      type="button"
                      onClick={toggleDialogExpiry}
                      className="btn-outline"
                    >
                      <p>
                        {selectedDateExpiry
                          ? format(new Date(selectedDateExpiry), 'yyyy-MM-dd')
                          : t('selectDate')}
                      </p>
                      <img
                        style={{ height: 'auto', rotate: '-90deg' }}
                        src={ChevronLeft}
                        alt=""
                        role="presentation"
                      />
                    </button>
                    <dialog ref={dialogRefExpiry} className="calendar-modal">
                      <Calendar
                        minDate={new Date()}
                        value={selectedDateExpiry}
                        defaultView="decade"
                        onChange={(date) => {
                          setSelectedDateExpiry(date as Value);
                          const formattedDate = format(
                            new Date(date as Date),
                            'yyyy-MM-dd'
                          );
                          setFieldValue(
                            'expiryDate',
                            Math.floor(new Date(formattedDate).getTime() / 1000)
                          );
                          toggleDialogExpiry();
                        }}
                        prevLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={LeftArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                        nextLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={RightArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                      />
                    </dialog>
                    <ErrorMessage
                      name="expiryDate"
                      component="div"
                      className="form__error"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="issueDate">{t('idIssueDate')}</label>
                  <div className="calendar-modal-wrapper">
                    <button
                      type="button"
                      onClick={toggleDialogIssue}
                      className="btn-outline"
                    >
                      <p>
                        {selectedDateIssue
                          ? format(new Date(selectedDateIssue), 'yyyy-MM-dd')
                          : t('selectDate')}
                      </p>
                      <img
                        style={{ height: 'auto', rotate: '-90deg' }}
                        src={ChevronLeft}
                        alt=""
                        role="presentation"
                      />
                    </button>
                    <dialog ref={dialogRefIssue} className="calendar-modal">
                      <Calendar
                        maxDate={new Date()}
                        value={selectedDateIssue}
                        defaultView="decade"
                        onChange={(date) => {
                          setSelectedDateIssue(date as Value);
                          const formattedDate = format(
                            new Date(date as Date),
                            'yyyy-MM-dd'
                          );
                          setFieldValue(
                            'issueDate',
                            Math.floor(new Date(formattedDate).getTime() / 1000)
                          );
                          toggleDialogIssue();
                        }}
                        prevLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={LeftArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                        nextLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={RightArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                      />
                    </dialog>
                    <ErrorMessage
                      name="issueDate"
                      component="div"
                      className="form__error"
                    />
                  </div>
                </div>
              </div>
              {values.identificationForm === 'nationalId' && (
                <div className="upload-container">
                  <div className="input-wrapper">
                    <label htmlFor="nationalIdFrontImage">
                      {t('nationalIdFrontImage')}
                    </label>
                    <div className="upload">
                      <UploadIcon />

                      {nationalIdFrontImageText.length ? (
                        <>
                          <span className="upload__file">
                            {nationalIdFrontImageText}
                          </span>
                          <button
                            className="upload__remove"
                            onClick={() => setNationalIdFrontImageText('')}
                          >
                            x
                          </button>
                        </>
                      ) : (
                        <span className="upload__placeholder">
                          {t('uploadImagePlaceholder')}
                        </span>
                      )}

                      <input
                        id="nationalIdFrontImageText"
                        name="nationalIdFrontImageText"
                        type="file"
                        accept="image/*,.pdf"
                        className={
                          errors.nationalIdFrontImage &&
                          touched.nationalIdFrontImage
                            ? 'error'
                            : ''
                        }
                        onClick={(event) => {
                          if (nationalIdFrontImageText.length) {
                            event.preventDefault();
                            event.currentTarget.value = '';
                            setNationalIdFrontImageText('');
                            setFieldValue('nationalIdFrontImage', {});
                          }
                        }}
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            setNationalIdFrontImageText(
                              event.currentTarget.files[0].name
                            );
                            setFieldValue(
                              'nationalIdFrontImage',
                              event.currentTarget.files[0]
                            );
                          }
                        }}
                      />
                    </div>

                    <ErrorMessage
                      name="nationalIdFrontImage"
                      component="div"
                      className="form__error"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="nationalIdBackImage">
                      {t('nationalIdBackImage')}
                    </label>
                    <div className="upload">
                      <UploadIcon />

                      {nationalIdBackImageText.length ? (
                        <>
                          <span className="upload__file">
                            {nationalIdBackImageText}
                          </span>
                          <button
                            className="upload__remove"
                            onClick={() => setNationalIdBackImageText('')}
                          >
                            x
                          </button>
                        </>
                      ) : (
                        <span className="upload__placeholder">
                          {t('uploadImagePlaceholder')}
                        </span>
                      )}
                      <input
                        id="nationalIdBackImage"
                        name="nationalIdBackImage"
                        type="file"
                        accept="image/*,.pdf"
                        className={
                          errors.nationalIdBackImage &&
                          touched.nationalIdBackImage
                            ? 'error'
                            : ''
                        }
                        onClick={(event) => {
                          if (nationalIdBackImageText.length) {
                            event.preventDefault();
                            event.currentTarget.value = '';
                            setNationalIdBackImageText('');
                            setFieldValue('jointBuyerNationalIdBackImage', {});
                          }
                        }}
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            setNationalIdBackImageText(
                              event.currentTarget.files[0].name
                            );
                            setFieldValue(
                              'nationalIdBackImage',
                              event.currentTarget.files[0]
                            );
                          }
                        }}
                      />
                    </div>

                    <ErrorMessage
                      name="nationalIdBackImage"
                      component="div"
                      className="form__error"
                    />
                  </div>
                </div>
              )}
              {values.identificationForm === 'passport' && (
                <div className="input-wrapper">
                  <label htmlFor="passportImage">{t('passportImage')}</label>
                  <div className="upload">
                    <UploadIcon />

                    {passportImageText.length ? (
                      <>
                        <span className="upload__file">
                          {passportImageText}
                        </span>
                        <button
                          className="upload__remove"
                          onClick={() => setPassportImageText('')}
                        >
                          x
                        </button>
                      </>
                    ) : (
                      <span className="upload__placeholder">
                        {t('uploadImagePlaceholder')}
                      </span>
                    )}
                    <input
                      id="passportImage"
                      name="passportImage"
                      type="file"
                      accept="image/*,.pdf"
                      className={
                        errors.passportImage && touched.passportImage
                          ? 'error'
                          : ''
                      }
                      onClick={(event) => {
                        if (passportImageText.length) {
                          event.preventDefault();
                          event.currentTarget.value = '';
                          setPassportImageText('');
                          setFieldValue('passportImage', '');
                        }
                      }}
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          setPassportImageText(
                            event.currentTarget.files[0].name
                          );
                          setFieldValue(
                            'passportImage',
                            event.currentTarget.files[0]
                          );
                        }
                      }}
                    />
                  </div>

                  <ErrorMessage
                    name="passportImage"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}

              <div className={styles['button-wrapper']}>
                <LoadingButton
                  className={`${styles['create-btn']} btn btn--navy`}
                  disabled={!isValid}
                  type="submit"
                  loading={pendingQualify}
                >
                  {t('saveAndProceed')}
                </LoadingButton>

                <Link
                  to={ROUTES['LeadPipelines'].getPath?.(leadId)}
                  className="anchor"
                >
                  {t('cancel')}
                </Link>
              </div>
            </Form>
          )}
        </Formik>
        <img
          src={qualifyImg}
          alt="new-deal"
          className={styles['image']}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default QualifyCustomer;

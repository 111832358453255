import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import greenTick from '../../assets/icons/green-tick.svg';
import styles from './create-sale.module.scss';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import { useCreateSaleContext } from './create-sale-context';

const SuccessStep = () => {
  const { selectedUnit } = useCreateSaleContext();
  const { t } = useTranslation();
  return (
    <div className={styles['success-container']}>
      <div className={styles['success-banner']}>
        <h5 className={styles['title']}>
          <img src={greenTick} alt="" role="presentation" />
          {t('saleCreatedSuccessfully')}
        </h5>
        <p className={styles['body']}>
          {t('congratulationsYouHaveCreatedASale')}{' '}
        </p>
        <Link to={ROUTES['MySales']?.path} className="btn btn--navy">
          {t('goToMySales')}
        </Link>
      </div>
      <div className={styles['unit']}>
        <p>{t('selectedUnit')}</p>
        {selectedUnit && (
          <PropertyCard
            unit={selectedUnit}
            portal="sales-man"
            handleRemove={undefined}
            listingView="grid"
            unitDetailsPath={ROUTES['UnitDetails'].getPath}
            hideCompare
          />
        )}
      </div>
    </div>
  );
};

export default SuccessStep;

import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { useTranslation } from 'react-i18next';
import styles from './lead-stages.module.scss';

interface Props extends Omit<LinkProps, 'to'> {
  leadId?: string;
}
const DisqualifyLink = ({ leadId, ...props }: Props) => {
  const { t } = useTranslation();

  if (!leadId) return null;

  return (
    <Link
      to={ROUTES['DisqualifyLead'].getPath?.(leadId)}
      className={styles['disqualify-link']}
      {...props}
    >
      {t('disqualifyLead')}
    </Link>
  );
};

export default DisqualifyLink;

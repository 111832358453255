import { format } from 'date-fns';
import { ErrorMessage, useFormikContext } from 'formik';
import { useRef } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import { LeadDiscoveryInput } from '../../definitions/interfaces/lead-stages.interface';
import styles from './initial-meeting.module.scss';

export type Value = Date | null;

const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export function InitialMeetingInput() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<LeadDiscoveryInput>();

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  const selectedDate = values.initalMeetingDate;

  return (
    <div className={styles['initial-meeting-input']}>
      <p className={styles['title']}>{t('initialMeeting')}</p>
      <div className={styles['meeting-date']}>
        <p>{t('meetingDate')}</p>

        <div className={styles['modal-wrapper']}>
          <button
            type="button"
            onClick={toggleDialog}
            className={styles['btn-outline']}
          >
            <p>
              {selectedDate
                ? formatDate(new Date(selectedDate))
                : t('selectDate')}
            </p>
            <img
              style={{ height: 'auto', rotate: '-90deg' }}
              src={ChevronLeft}
              alt=""
              role="presentation"
            />
          </button>
          <dialog ref={dialogRef} className={styles['modal']}>
            <Calendar
              minDate={new Date()}
              value={
                values.initalMeetingDate
                  ? new Date(values.initalMeetingDate)
                  : new Date()
              }
              onChange={(date) => {
                setFieldValue?.('initalMeetingDate', formatDate(date as Date));
                toggleDialog();
              }}
              view={'month'}
              prevLabel={
                <img
                  style={{ height: 'auto' }}
                  src={LeftArrowIcon}
                  alt=""
                  role="presentation"
                />
              }
              nextLabel={
                <img
                  style={{ height: 'auto' }}
                  src={RightArrowIcon}
                  alt=""
                  role="presentation"
                />
              }
            />
          </dialog>
          <ErrorMessage
            name="initalMeetingDate"
            className={'form__error'}
            component="div"
          />
        </div>
      </div>
    </div>
  );
}

export const InitialMeetingData = ({
  initialMeetingDate,
}: {
  initialMeetingDate?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['initial-meeting']}>
      <p className={styles['title']}>{t('initialMeeting')}</p>
      <div className={styles['meeting-date']}>
        <p>{t('meetingDate')}:</p>
        {initialMeetingDate ? (
          <p>{formatDate(new Date(initialMeetingDate))}</p>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};
export default InitialMeetingInput;

import { LoadingButton } from '@orascom/common-components';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import PlusIcon from '../../assets/icons/plus--white.svg';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { Value } from '../initial-meeting/initial-meeting';
import styles from './deal-scheduled-meetings.module.scss';

interface AddNewMeetingProps {
  refetchMeetgins: () => void;
}
const AddNewMeeting = ({ refetchMeetgins }: AddNewMeetingProps) => {
  const { t } = useTranslation();
  const { selectedPipeline, setPipelines, refetchPipelines } =
    useUserPipelineContext();
  const [selectedDate, setSelectedDate] = useState<Value>(null);
  const [pendingMeetCreat, setPendingMeetCreat] = useState(false);
  const [fetchingPipelines, setFetchingPipelines] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState('');
  const [addMeetingTouched, setAddMeetingTouched] = useState(false);

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const submitMeeting = () => {
    setAddMeetingTouched(true);
    if (!selectedDate || !selectedPipeline) return;

    const pipelineSourceId = selectedPipeline?.pipeline_source_id;
    const destinationSlug = selectedPipeline?.destination_slug;

    setPendingMeetCreat(true);
    const timestampMilliseconds = selectedDate?.getTime() ?? 0;

    DealUtils.createMeeting(
      pipelineSourceId,
      Math.floor(timestampMilliseconds / 1000),
      destinationSlug,
      meetingNotes
    )
      .then(() => {
        setPendingMeetCreat(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        setFetchingPipelines(false);
        refetchMeetgins();

        toast.success('Meeting added successfully!');
      })
      .catch(() => {
        toast.error('Error adding meeting');
      })
      .finally(() => {
        setPendingMeetCreat(false);
        setFetchingPipelines(false);
      });
  };

  const toggleCalendarDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  const submitLoadingText = fetchingPipelines
    ? t('configuringPipeline..')
    : t('addingMeeting..');

  return (
    <div className={styles['meeting-date']}>
      <p>{t('meetingDate')}</p>

      <div className={styles['modal-wrapper']}>
        <button
          type="button"
          onClick={toggleCalendarDialog}
          className={styles['btn-outline']}
        >
          <p>
            {selectedDate
              ? format(new Date(selectedDate), 'dd MMMM yyyy')
              : t('selectDate')}
          </p>
          <img
            style={{ height: 'auto', rotate: '-90deg' }}
            src={ChevronLeft}
            alt=""
            role="presentation"
          />
        </button>
        {!selectedDate && addMeetingTouched && (
          <div className="form__error">{t('selectDateRequired')}</div>
        )}
        <dialog ref={dialogRef} className={styles['modal']}>
          <Calendar
            value={selectedDate}
            onChange={(date) => {
              setSelectedDate(date as Value);
              toggleCalendarDialog();
            }}
            view={'month'}
            prevLabel={
              <img
                style={{ height: 'auto' }}
                src={LeftArrowIcon}
                alt=""
                role="presentation"
              />
            }
            nextLabel={
              <img
                style={{ height: 'auto' }}
                src={RightArrowIcon}
                alt=""
                role="presentation"
              />
            }
          />
        </dialog>
      </div>

      <p>
        {t('meetingDescription')} ({t('optional')})
      </p>
      <textarea
        className={styles['meeting-notes']}
        value={meetingNotes}
        onChange={(e) => setMeetingNotes(e.target.value)}
        placeholder={t('meetingNotesPlaceholder')}
      />

      <LoadingButton
        className={`btn btn--navy ${styles['add-meeting-btn']}`}
        loading={pendingMeetCreat || fetchingPipelines}
        loadingText={submitLoadingText}
        spinnerSize="sm"
        onClick={() => submitMeeting()}
      >
        <img src={PlusIcon} alt="" role="presentation" />
        {t('addMeeting')}
      </LoadingButton>
    </div>
  );
};

export default AddNewMeeting;

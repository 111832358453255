import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './my-customers.module.scss';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { ROUTES } from '../../api/routes';
// import { renderLeadStatusIcon } from '../../utils/deal.utils';
import { format } from 'date-fns';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { Customer } from '../../utils/customer';
import { Customers as CustomersInterface } from '../../definitions/interfaces/customers.interface';
import { Loader, debounce } from '@orascom/common-components';
import searchIcon from '../../assets/icons/search.svg';

export function MyCustomers() {
  const { t } = useTranslation();

  const [customersData, setCustomersData] = useState<CustomersInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = customersData.slice(indexOfFirstItem, indexOfLastItem);
  const [loading, setLoading] = useState(true);
  const [numberSearched, setNumberSearched] = useState('');

  const searchDeals = (value: string) => {
    if (!value.length) {
      setLoading(true);
      setNumberSearched('');
    }
    setLoading(true);
    setNumberSearched(value);
  };
  const debouncedOnChange = debounce(searchDeals, 1000);
  const viewNumber = 1; // 1 = customer leads to be fetched from the backend

  useEffect(() => {
    Customer.getCustomers(viewNumber, numberSearched)
      .then((res) => {
        setCustomersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [numberSearched]);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myCustomers')}</h1>
      <div className={styles['search-input']}>
        <img src={searchIcon} alt="" role="presentation" />
        <input
          type="text"
          placeholder={t('searchLead')}
          onChange={(e) => debouncedOnChange(e.target.value)}
        />
      </div>
      <div className={loading ? '' : styles['table']}>
        {loading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length ? (
          <div className="analytics-table">
            <table>
              <thead>
                <tr className={styles['tr-grid']}>
                  <th>{t('fullName')}</th>
                  <th>{t('phoneNumber')}</th>
                  <th>{t('email')}</th>
                  <th>{t('modifiedOn')}</th>
                  <th>{t('ownerName')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((item) => {
                  const date = new Date(item.source_updated_at);
                  const formattedDate = format(date, 'dd MMMM yyyy');
                  return (
                    <tr className={styles['tr-grid']} key={uuidv4()}>
                      <td className="manager-td--name ">
                        {item.name ? item.name : '-'}
                      </td>
                      <td className="sales-man-td">
                        {item.phone ? item.phone : '-'}
                      </td>
                      <td
                        className="sales-man-td"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {item.email ? item.email : '-'}
                      </td>
                      <td className="sales-man-td">
                        {formattedDate ? formattedDate : '-'}
                      </td>
                      <td className="sales-man-td ">
                        {item.owner_name ? item.owner_name : '-'}
                      </td>
                      <td>
                        <div className={styles['last-column']}>
                          <Link
                            to={ROUTES['CustomerCreatePipelines'].getPath?.(
                              item.customer_source_id
                            )}
                            state={{
                              newPipeline: true,
                            }}
                          >
                            + {t('createSale')}
                          </Link>
                          <Link
                            to={ROUTES['CustomerPipelines'].getPath?.(
                              item.customer_source_id
                            )}
                          >
                            <span className="anchor">
                              {t('customerDetails')}
                            </span>{' '}
                            <img src={arrowIcon} alt="arrow" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>{t('noCustomersToDisplay')}</p>
        )}
      </div>
      {!loading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > customersData.length
                ? customersData.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{customersData.length} </span>
            {t('customers')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={customersData.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyCustomers;

import {
  DealInterface,
  NewDealFormInterface,
  Meetings,
  Feedback,
  DealCreationResponse,
  UpdatePipeline,
} from '../definitions/interfaces/deals.interface';
import { Payload, OptionValue } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { Deal as DealAPI } from '../api/deal';
import {
  DisqalifyLeadPayload,
  FeedbackSubmission,
  LeadCreationResponse,
  LeadDetails,
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../definitions/interfaces/lead-stages.interface';
import * as yup from 'yup';
import { mapToOption } from './options-maping';

export class Deal {
  public static createLead(
    leadData: NewDealFormInterface
  ): Promise<Payload<Payload<DealCreationResponse>>> {
    return DealAPI.createLead(leadData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static createLeadDiscoveryPipeline(
    leadData: LeadDiscoveryInterface
  ): Promise<LeadCreationResponse> {
    return DealAPI.createLeadDiscoveryPipeline(leadData)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static disqualifyLead(leadId: string, leadData: DisqalifyLeadPayload) {
    return DealAPI.disqualifyLead(leadId, leadData)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getLeads(searchQuery: string): Promise<DealInterface[]> {
    return DealAPI.getLeads(searchQuery)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getLeadDetails(id: string): Promise<LeadDetails> {
    return DealAPI.getLeadDetails(id)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getPipelineDetails(leadId: string): Promise<PipelineDetails[]> {
    return DealAPI.getPipelineDetails(leadId)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getBrokerList(): Promise<OptionValue[]> {
    return DealAPI.getBrokerList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'broker_name',
          value: 'broker_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getAmbassadorsList(): Promise<OptionValue[]> {
    return DealAPI.getAmbassadorsList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'ambassador_name',
          value: 'ambassador_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getEventsList(): Promise<OptionValue[]> {
    return DealAPI.getEventsList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'event_name',
          value: 'event_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listDesiredUnit(): Promise<OptionValue[]> {
    return DealAPI.listDesiredUnit()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'unit_name',
          value: 'unit_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getExhibitionsList(): Promise<OptionValue[]> {
    return DealAPI.getExhibitionsList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'exhibition_name',
          value: 'exhibition_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getWalkInList(): Promise<OptionValue[]> {
    return DealAPI.getWalkInList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'office_name',
          value: 'office_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getCurrenciesList(): Promise<OptionValue[]> {
    return DealAPI.getCurrenciesList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'name',
          value: 'source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getCompetitionList(): Promise<OptionValue[]> {
    return DealAPI.getCompetitionList()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data.data, {
          label: 'competitor_name',
          value: 'competitor_source_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listFeedback(pipelineSourceId: string): Promise<Feedback[]> {
    return DealAPI.listFeedback(pipelineSourceId)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listMeetings(pipelineSourceId: string): Promise<Meetings[]> {
    return DealAPI.listMeetings(pipelineSourceId)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static createMeeting(
    pipelineSourceId: string,
    meetingDate: number,
    destinationSlug: string,
    meetingNotes?: string
  ): Promise<Meetings[]> {
    return DealAPI.createMeeting(
      pipelineSourceId,
      meetingDate,
      destinationSlug,
      meetingNotes
    )
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static updateDealPipeline(
    pipelineSourceId: string,
    leadData: UpdatePipeline | FeedbackSubmission
  ): Promise<LeadCreationResponse> {
    return DealAPI.updateDealPipeline(pipelineSourceId, leadData)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
export enum LeadChannelValues {
  INSTAGRAM = 1,
  GOOGLE = 2,
  FACEBOOK = 3,
  LINKED_IN = 4,
  ONLINE = 5,
  MOBILE_APP = 7,
  INFO_EMAIL = 8,
  CALL_CENTER = 9,
  SMS_CAMPAIGN = 10,
  OUTDOOR_ADVERTISEMENT = 11,
  MAGAZINE = 12,
  PERSONAL_CONTACT = 13,
  DIRECT_MANAGER = 14,
  HEAD_OFFICE = 15,
  WALK_IN = 16,
  EMPLOYEE_STAFF = 17,
  REFERRALS = 25,
  BROKER = 26,
  AMBASSADOR = 27,
  CORPORATE_SALES_TEAM = 28,
  EXPIRED_CIL = 29,
  EXHIBITIONS = 30,
  EVENTS = 31,
  EMPLOYEE_REFERRAL = 32,
  TIKTOK = 33,
  DIRECTOR = 34,
  CUSTOMER_PORTAL = 36,
  BROKER_PORTAL = 37,
}
export const leadChannelOptions = [
  { value: LeadChannelValues.AMBASSADOR, label: 'Ambassador' },
  { value: LeadChannelValues.BROKER, label: 'Broker' },
  { value: LeadChannelValues.DIRECT_MANAGER, label: 'Direct Manager' },
  { value: LeadChannelValues.DIRECTOR, label: 'Director' },
  { value: LeadChannelValues.EMPLOYEE_STAFF, label: 'Employee Staff' },
  { value: LeadChannelValues.EVENTS, label: 'Events' },
  { value: LeadChannelValues.EXHIBITIONS, label: 'Exhibitions' },
  { value: LeadChannelValues.HEAD_OFFICE, label: 'Head Office' },
  { value: LeadChannelValues.ONLINE, label: 'Online' },
  { value: LeadChannelValues.PERSONAL_CONTACT, label: 'Personal Contact' },
  { value: LeadChannelValues.REFERRALS, label: 'Referrals' },
  { value: LeadChannelValues.WALK_IN, label: 'Walk In' },
];

export const leadChannel: { [key: number]: string } = {
  [LeadChannelValues.AMBASSADOR]: 'Ambassador',
  [LeadChannelValues.BROKER]: 'Broker',
  [LeadChannelValues.DIRECT_MANAGER]: 'Direct Manager',
  [LeadChannelValues.DIRECTOR]: 'Director',
  [LeadChannelValues.EMPLOYEE_STAFF]: 'Employee Staff',
  [LeadChannelValues.EVENTS]: 'Events',
  [LeadChannelValues.EXHIBITIONS]: 'Exhibitions',
  [LeadChannelValues.HEAD_OFFICE]: 'Head Office',
  [LeadChannelValues.ONLINE]: 'Online',
  [LeadChannelValues.PERSONAL_CONTACT]: 'Personal Contact',
  [LeadChannelValues.REFERRALS]: 'Referrals',
  [LeadChannelValues.WALK_IN]: 'Walk In',
};

export enum ObjectionValues {
  NONE = 1,
  UNIT_PRICE = 2,
  PAYMENT = 3,
  LOCATION = 4,
  COMPETITION = 5,
  DESIRED_TYPE_NOT_AVAILABLE = 6,
  DESIRED_UNIT_NOT_AVAILABLE = 7,
  OTHERS = 8,
}
export const ObjectionOptions = [
  { value: ObjectionValues.UNIT_PRICE, label: 'Unit Price' },
  { value: ObjectionValues.PAYMENT, label: 'Payment' },
  { value: ObjectionValues.LOCATION, label: 'Location' },
  { value: ObjectionValues.COMPETITION, label: 'Competition' },
  {
    value: ObjectionValues.DESIRED_TYPE_NOT_AVAILABLE,
    label: 'Desired type not available',
  },
  {
    value: ObjectionValues.DESIRED_UNIT_NOT_AVAILABLE,
    label: 'Desired unit not available',
  },
  { value: ObjectionValues.OTHERS, label: 'Others' },
  { value: ObjectionValues.NONE, label: 'None' },
];
export const customerInquiryOptions = [
  { label: 'investment inquiry', value: '1' },
  { label: 'unit purchasing inquiry', value: '2' },
  { label: 'general inquiry', value: '3' },
];

export const leadDiscoveryFeedbackOptionsEnum = {
  FOLLOW_UP: 1,
  NO_ANSWER: 2,
  INTERESTED: 3,
  UNIT_TYPE_NOT_AVAILABLE: 4,
  PAYMENT_TERMS: 5,
  COMPETITION: 6,
  PRICE_CONCERN: 7,
  PENDING_ON_SALES_ADVISOR: 8,
  PRODUCT_CONCERN: 9,
  NOT_INTERESTED: 10,
  NOT_ELIGIBLE: 11,
  WRONG_NUMBER: 12,
  NOT_RESPONDING: 13,
  OTHERS: 14,
};

export const leadCreationFeedbackOptionsEnum = {
  FOLLOW_UP: 8,
  INTERESTED: 1,
  NOT_INTERESTED: 2,
  NO_ANSWER: 3,
  NOT_ELIGIBLE: 5,
  WRONG_NUMBER: 7,
  PRICE_CONCERN: 9,
  PRODUCT_CONCERN: 10,
  PAYMENT_TERMS: 11,
  COMPETITION: 12,
  PENDING_ON_SALES_ADVISOR: 13,
  NOT_RESPONDING: 14,
  OTHERS: 15,
};
export const leadCreationFeedbackOptions = [
  {
    label: 'Follow Up',
    value: leadCreationFeedbackOptionsEnum.FOLLOW_UP,
  },
  {
    label: 'No Answer',
    value: leadCreationFeedbackOptionsEnum.NO_ANSWER,
  },
  {
    label: 'Interested',
    value: leadCreationFeedbackOptionsEnum.INTERESTED,
  },
];

export const feedbackOptions = [
  {
    label: 'Follow Up',
    value: leadDiscoveryFeedbackOptionsEnum.FOLLOW_UP,
  },
  {
    label: 'No Answer',
    value: leadDiscoveryFeedbackOptionsEnum.NO_ANSWER,
  },
  {
    label: 'Interested',
    value: leadDiscoveryFeedbackOptionsEnum.INTERESTED,
  },
  {
    label: 'Payment Term',
    value: leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS,
  },
  {
    label: 'Competition',
    value: leadDiscoveryFeedbackOptionsEnum.COMPETITION,
  },
  {
    label: 'Price Concern',
    value: leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN,
  },
  {
    label: 'Pending on Sales Advisor',
    value: leadDiscoveryFeedbackOptionsEnum.PENDING_ON_SALES_ADVISOR,
  },
  {
    label: 'Product Concern',
    value: leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN,
  },
  {
    label: 'Not Interested',
    value: leadDiscoveryFeedbackOptionsEnum.NOT_INTERESTED,
  },
  {
    label: 'Not Eligible',
    value: leadDiscoveryFeedbackOptionsEnum.NOT_ELIGIBLE,
  },
  {
    label: 'Wrong Number',
    value: leadDiscoveryFeedbackOptionsEnum.WRONG_NUMBER,
  },
  {
    label: 'Not Responding',
    value: leadDiscoveryFeedbackOptionsEnum.NOT_RESPONDING,
  },
  {
    label: 'Others',
    value: leadDiscoveryFeedbackOptionsEnum.OTHERS,
  },
];

export const issuesOptions = [
  {
    label: 'Down payment',
    value: 1,
  },
  {
    label: 'Installments Amount',
    value: 2,
  },
  {
    label: 'Installments Frequency',
    value: 3,
  },
  {
    label: 'Installments Duration',
    value: 4,
  },
];

export const competitorAdvantageOptions = [
  {
    label: 'Payment Terms',
    value: 1,
  },
  {
    label: 'Product Availabilty',
    value: 2,
  },
  {
    label: 'Price to Product',
    value: 3,
  },
  {
    label: 'Early Delivery',
    value: 4,
  },
  {
    label: 'Location',
    value: 5,
  },
  {
    label: 'Other',
    value: 6,
  },
];

export const booleanDropdownOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
export enum DefineIssueValues {
  CONCERN = 1,
  AVAILABILTY = 2,
}
export const defineIssuesOptions = [
  {
    label: 'Concern',
    value: DefineIssueValues.CONCERN,
  },
  {
    label: 'Availabilty',
    value: DefineIssueValues.AVAILABILTY,
  },
];

export enum ConcernOptionsEnum {
  BEACH_ACCESS = 1,
  PRODUCT_VALUE = 2,
  DELIVERY_TIME = 3,
  OTHERS = 4,
}

export const concernOptions = [
  { label: 'Beach Access', value: ConcernOptionsEnum.BEACH_ACCESS },
  { label: 'Product Value', value: ConcernOptionsEnum.PRODUCT_VALUE },
  { label: 'Delivery Time', value: ConcernOptionsEnum.DELIVERY_TIME },
  { label: 'Others', value: ConcernOptionsEnum.OTHERS },
];

export enum DesiredProductOptionsEnum {
  TWIN_VILLAS = 100000003,
  TOWN_HOUSE = 100000005,
  TOWNHOUSE_MIDDLE = 100000000,
  TOWNHOUSE_CORNER = 100000001,
  MANSION = 100000004,
  STANDALONE_VILLA = 127990000,
  FAMILY_HOUSE = 753240030,
  APARTMENT = 100000002,
  APARTMENT_NANNY_ROOM = 100000006,
  APARTMENT_NANNY_FAMILY_ROOM = 100000007,
  ONE_BEDROOM = 753240029,
  TWO_BEDROOMS = 753240026,
  THREE_BEDROOMS = 753240027,
  FOUR_BED_SPECIAL = 753240028,
  DUPLEX = 100000008,
  PENTHOUSE = 100000009,
  LOFT = 100000010,
  DUETTES = 753240000,
  QUATTROS = 753240001,
  SOLOS_1 = 753240002,
  SOLOS_2 = 753240003,
  RUBIKS = 753240004,
  OTHER = 753240032,
}

export const desiredProductOptions = [
  { label: 'Twin Villas', value: DesiredProductOptionsEnum.TWIN_VILLAS },
  { label: 'Town house', value: DesiredProductOptionsEnum.TOWN_HOUSE },
  {
    label: 'Townhouse Middle',
    value: DesiredProductOptionsEnum.TOWNHOUSE_MIDDLE,
  },
  {
    label: 'Townhouse Corner',
    value: DesiredProductOptionsEnum.TOWNHOUSE_CORNER,
  },
  { label: 'Mansion', value: DesiredProductOptionsEnum.MANSION },
  {
    label: 'Standalone Villa',
    value: DesiredProductOptionsEnum.STANDALONE_VILLA,
  },
  { label: 'Family House', value: DesiredProductOptionsEnum.FAMILY_HOUSE },
  { label: 'Apartment', value: DesiredProductOptionsEnum.APARTMENT },
  {
    label: 'Apartment + Nanny Room',
    value: DesiredProductOptionsEnum.APARTMENT_NANNY_ROOM,
  },
  {
    label: 'Apartment + Nanny Room + Family Room',
    value: DesiredProductOptionsEnum.APARTMENT_NANNY_FAMILY_ROOM,
  },
  { label: '1 Bedroom', value: DesiredProductOptionsEnum.ONE_BEDROOM },
  { label: '2 Bedrooms', value: DesiredProductOptionsEnum.TWO_BEDROOMS },
  { label: '3 Bedrooms', value: DesiredProductOptionsEnum.THREE_BEDROOMS },
  { label: '4 Bed Special', value: DesiredProductOptionsEnum.FOUR_BED_SPECIAL },
  { label: 'Duplex', value: DesiredProductOptionsEnum.DUPLEX },
  { label: 'Penthouse', value: DesiredProductOptionsEnum.PENTHOUSE },
  { label: 'Loft', value: DesiredProductOptionsEnum.LOFT },
  { label: 'Duettes', value: DesiredProductOptionsEnum.DUETTES },
  { label: 'Quattros', value: DesiredProductOptionsEnum.QUATTROS },
  { label: 'Solos 1', value: DesiredProductOptionsEnum.SOLOS_1 },
  { label: 'Solos 2', value: DesiredProductOptionsEnum.SOLOS_2 },
  { label: 'Rubiks', value: DesiredProductOptionsEnum.RUBIKS },
  { label: 'Other', value: DesiredProductOptionsEnum.OTHER },
];

export const conditionalFields = {
  moreDetails: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  issue: yup.number().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS,
    then: yup
      .number()
      .required('Required')
      .nullable()
      .moreThan(0, 'Please choose an option'),
    otherwise: yup.number().nullable(),
  }),
  followUpdate: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.FOLLOW_UP,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  lostCompetitorSourceId: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.COMPETITION,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  furtherDetails: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.COMPETITION,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  competitorAdvantage: yup.number().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.COMPETITION,
    then: yup
      .number()
      .required('Required')
      .moreThan(0, 'Please choose an option'),
    otherwise: yup.number(),
  }),
  expectedBudget: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN,
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  currency: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  futureBudgetAvailability: yup
    .boolean()
    .when('feedbackId', {
      is: leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN,
      then: yup.boolean().required('Required'),
      otherwise: yup.boolean(),
    })
    .transform((value) => value ?? undefined),
  expectedDate: yup.string().when(['feedbackId', 'futureBudgetAvailability'], {
    is: (feedback_id: number, future_budget_availability: number) =>
      feedback_id === leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN &&
      future_budget_availability,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  defineIssue: yup.number().when('feedbackId', {
    is: (value: number) =>
      value === leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN,
    then: yup
      .number()
      .nullable()
      .required('Required')
      .moreThan(0, 'Please choose an option'),
    otherwise: yup.number().nullable(),
  }),
  futureOpportunity: yup
    .boolean()
    .nullable()
    .when('feedbackId', {
      is: (value: number) =>
        value === leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN ||
        value === leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN,
      then: yup.boolean().nullable().required('Required'),
      otherwise: yup.boolean().nullable(),
    }),

  clarifyReasons: yup.string().when('feedbackId', {
    is: leadDiscoveryFeedbackOptionsEnum.OTHERS,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  desiredProduct: yup.string().when(['feedbackId', 'defineIssue'], {
    is: (feedback_id: number, defineIssue: number) =>
      feedback_id === leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
      defineIssue === DefineIssueValues.AVAILABILTY,
    then: yup.string().nullable().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  concern: yup.string().when(['feedbackId', 'defineIssue'], {
    is: (feedback_id: number, defineIssue: number) =>
      feedback_id === leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
      defineIssue === DefineIssueValues.CONCERN,
    then: yup.string().nullable().required('Required'),
    otherwise: yup.string().nullable(),
  }),

  productDetails: yup
    .string()
    .when(['feedbackId', 'desiredProduct', 'concern', 'defineIssue'], {
      is: (
        feedback_id: number,
        desiredProduct: string | number,
        concern: string | number,
        defineIssue: number
      ) =>
        feedback_id === leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
        ((Number(desiredProduct) === DesiredProductOptionsEnum.OTHER &&
          defineIssue === DefineIssueValues.AVAILABILTY) ||
          (defineIssue === DefineIssueValues.CONCERN &&
            Number(concern) === ConcernOptionsEnum.OTHERS)),
      then: yup.string().nullable().required('Required'),
      otherwise: yup.string().nullable(),
    }),
};

export const storeTokenDetails = (token: string): void => {
  if (process.env['NX_SALESMAN_ACCESS_TOKEN_KEY']) {
    localStorage.setItem(process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'], token);
  }
};
export const removeTokenDetails = (): void => {
  if (process.env['NX_SALESMAN_ACCESS_TOKEN_KEY']) {
    localStorage.removeItem(process.env['NX_SALESMAN_ACCESS_TOKEN_KEY']);
  }
};

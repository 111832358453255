import { UpdatePipeline } from '../../definitions/interfaces/deals.interface';
import { PipelineDetails } from '../../definitions/interfaces/lead-stages.interface';

interface UpdataPiplineArgs {
  pipeline: PipelineDetails;
  leadId?: string;
  customerId?: string;
  timestampMilliseconds: number;
  unit_id: string;
  destination_slug: string;
}
export function buildUpdatePipelinePayload({
  pipeline,
  unit_id,
  leadId,
  customerId,
  timestampMilliseconds,
  destination_slug,
}: UpdataPiplineArgs): UpdatePipeline {
  return {
    lead_source_id: leadId ?? '',
    customer_source_id: customerId ?? '',
    destination_slug: destination_slug,
    objections_handled: false,
    feedback_id: pipeline.feedback_id,
    sales_comment: '',
    unit_id: unit_id,
    closure_date: Math.floor(timestampMilliseconds / 1000),
  };
}

import { handleError } from '@orascom/utils';
import { Customer as CustomerAPI } from '../api/customers';
import {
  CustomerDetails,
  Customers as CustomerInterface,
} from '../definitions/interfaces/customers.interface';
import { Payload } from '@orascom/api-interfaces';
import { PipelineDetails } from '../definitions/interfaces/lead-stages.interface';

export class Customer {
  public static getCustomers(
    viewNumber: number,
    numberSearched: string
  ): Promise<Payload<Payload<CustomerInterface[]>>> {
    const searchQuery = new URLSearchParams();
    searchQuery.append('view_number', String(viewNumber));
    if (numberSearched.length) {
      searchQuery.append('search', String(numberSearched));
    }

    const query = numberSearched.length
      ? `/search?${searchQuery.toString()}`
      : `?${searchQuery.toString()}`;

    return CustomerAPI.getCustomers(query)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getCustomerDetails(
    customerId: string
  ): Promise<CustomerDetails> {
    return CustomerAPI.getCustomerDetails(customerId)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getCustomerPipeline(
    customerId: string
  ): Promise<PipelineDetails[]> {
    return CustomerAPI.getCustomerPipeline(customerId)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  Ambassador,
  Exhibition,
  WalkIn,
  Event as EventType,
  NewDealFormInterface,
  DealInterface,
  Broker,
  Meetings,
  UpdatePipeline,
  Feedback,
  DesiredUnit,
  DealCreationResponse,
  Competition,
  Currency,
} from '../definitions/interfaces/deals.interface';
import {
  DisqalifyLeadPayload,
  FeedbackSubmission,
  LeadCreationResponse,
  LeadDetails,
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../definitions/interfaces/lead-stages.interface';

export class Deal {
  public static createLead(
    leadData: NewDealFormInterface
  ): Promise<Payload<Payload<DealCreationResponse>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createLead.url}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      }
    );
  }

  public static createLeadDiscoveryPipeline(
    leadData: LeadDiscoveryInterface
  ): Promise<Payload<Payload<LeadCreationResponse>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createLeadDiscoveryPipeline.url}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      }
    );
  }

  public static disqualifyLead(leadId: string, leadData: DisqalifyLeadPayload) {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.disqualifyLead.url(leadId)}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      }
    );
  }

  public static getLeads(
    searchQuery: string
  ): Promise<Payload<Payload<DealInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getLeads.url(
        searchQuery
      )}`,
      {
        method: ENDPOINTS.getLeads.method,
      }
    );
  }

  public static getLeadDetails(
    id: string
  ): Promise<Payload<Payload<LeadDetails>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getLeadDetails.url(id)}`,
      {
        method: ENDPOINTS.getLeads.method,
      }
    );
  }

  public static getPipelineDetails(
    leadId: string
  ): Promise<Payload<PipelineDetails[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getPipelineDetails.url(leadId)}`,
      {
        method: ENDPOINTS.getPipelineDetails.method,
      }
    );
  }

  public static getAmbassadorsList(): Promise<Payload<Payload<Ambassador[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getAmbassadorsList.url}`,
      {
        method: ENDPOINTS.getAmbassadorsList.method,
      }
    );
  }

  public static getEventsList(): Promise<Payload<Payload<EventType[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getEventsList.url}`,
      {
        method: ENDPOINTS.getEventsList.method,
      }
    );
  }

  public static getExhibitionsList(): Promise<Payload<Payload<Exhibition[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getExhibitionsList.url}`,
      {
        method: ENDPOINTS.getExhibitionsList.method,
      }
    );
  }

  public static getWalkInList(): Promise<Payload<Payload<WalkIn[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getWalkInList.url}`,
      {
        method: ENDPOINTS.getWalkInList.method,
      }
    );
  }

  public static getCompetitionList(): Promise<Payload<Payload<Competition[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getCompetitionList.url}`,
      {
        method: ENDPOINTS.getCompetitionList.method,
      }
    );
  }

  public static listDesiredUnit(): Promise<Payload<Payload<DesiredUnit[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listDesiredUnit.url}`,
      {
        method: ENDPOINTS.listDesiredUnit.method,
      }
    );
  }

  public static getBrokerList(): Promise<Payload<Payload<Broker[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getBrokerList.url}`,
      {
        method: ENDPOINTS.getBrokerList.method,
      }
    );
  }

  public static listFeedback(
    pipelineSourceId: string
  ): Promise<Payload<Payload<Feedback[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listFeedback.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.listFeedback.method,
      }
    );
  }

  public static listMeetings(
    pipelineSourceId: string
  ): Promise<Payload<Payload<Meetings[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listMeetings.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.listMeetings.method,
      }
    );
  }

  public static createMeeting(
    pipelineSourceId: string,
    meetingDate: number,
    destinationSlug: string,
    meetingNotes?: string
  ): Promise<Payload<Payload<Meetings[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createMeeting.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.createMeeting.method,
        body: JSON.stringify({
          meeting_date: meetingDate,
          destination_slug: destinationSlug,
          meeting_comment: meetingNotes ?? null,
        }),
      }
    );
  }

  public static updateDealPipeline(
    pipelineSourceId: string,
    leadData: UpdatePipeline | FeedbackSubmission
  ): Promise<Payload<Payload<LeadCreationResponse>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.updateDealPipeline.url(pipelineSourceId)}`,
      {
        method: ENDPOINTS.updateDealPipeline.method,
        body: JSON.stringify(leadData),
      }
    );
  }

  public static getCurrenciesList(): Promise<Payload<Currency[]>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getCurrenciesList.url}`,
      {
        method: ENDPOINTS.getCurrenciesList.method,
      }
    );
  }
}

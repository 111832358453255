const endpointsPrefix = process.env['NX__SALESMAN_ENDPOINT_PREFIX'] ?? '';
export const ENDPOINTS = {
  getUserData: {
    url: `/user`,
    method: 'GET',
  },
  listSalesManDestinations: {
    url: `${endpointsPrefix}/destinations`,
    method: 'GET',
  },
  getSalesManDestinationInfo: {
    url: (destinationSlug: string) =>
      `${endpointsPrefix}/destinations/${destinationSlug}`,
    method: 'GET',
  },
  getSalesManDestinationLocations: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/locations`,
    method: 'GET',
  },
  getSalesManDestinationFacilities: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/facilities`,
    method: 'GET',
  },
  getSalesManDestinationProjects: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/projects`,
    method: 'GET',
  },
  getSalesManDestinationFAQ: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/faqs`,
    method: 'GET',
  },
  getSalesManFilters: {
    url: `${endpointsPrefix}/filters`,
    method: 'GET',
  },
  getSalesManUnits: {
    url: (filters?: string): string =>
      `${endpointsPrefix}/units${filters ?? ''}`,
    method: 'GET',
  },
  addSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'POST',
  },

  deleteSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'DELETE',
  },
  compareUnits: {
    path: `${endpointsPrefix}/units/compare`,
    method: 'GET',
  },

  getSalesManUnitDetails: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}`,
    method: 'GET',
  },

  getSalesManUnitPaymentTerms: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/payment-terms`,
    method: 'GET',
  },
  downloadSalesManOmanSalesOffer: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/sales-offer`,
    method: 'GET',
  },
  downloadSaleReservationForm: {
    url: (unitId?: number, salesId?: string) =>
      `${endpointsPrefix}/units/${unitId}/${salesId}/reservation-form`,
    method: 'GET',
  },
  uploadSaleAttachment: {
    url: (unitId: string, salesId: string) =>
      `${endpointsPrefix}/units/${unitId}/sales/${salesId}/attach`,
    method: 'GET',
  },
  createLead: {
    url: `${endpointsPrefix}/leads`,
    method: 'POST',
  },
  createLeadDiscoveryPipeline: {
    url: `${endpointsPrefix}/pipelines`,
    method: 'POST',
  },
  disqualifyLead: {
    url: (lead_id: string) => `${endpointsPrefix}/leads/${lead_id}/disqualify`,
    method: 'POST',
  },
  getLeadDetails: {
    url: (id: string) => `${endpointsPrefix}/leads/${id}`,
    method: 'GET',
  },
  getPipelineDetails: {
    url: (leadId: string) =>
      `${endpointsPrefix}/pipelines?lead_source_id=${leadId}&view_number=1`,
    method: 'GET',
  },
  getFeedbackList: {
    url: `${endpointsPrefix}/ambassadors?view_number=1`,
    method: 'GET',
  },
  getAmbassadorsList: {
    url: `${endpointsPrefix}/ambassadors?view_number=1`,
    method: 'GET',
  },
  getBrokerList: {
    url: `${endpointsPrefix}/brokers?view_number=1`,
    method: 'GET',
  },
  getEventsList: {
    url: `${endpointsPrefix}/events?view_number=1`,
    method: 'GET',
  },
  getCompetitionList: {
    url: `${endpointsPrefix}/competitions?view_number=1`,
    method: 'GET',
  },
  getExhibitionsList: {
    url: `${endpointsPrefix}/exhibitions?view_number=1`,
    method: 'GET',
  },
  getWalkInList: {
    url: `${endpointsPrefix}/offices?view_number=1`,
    method: 'GET',
  },
  getLeads: {
    url: (searchQuery: string) => `${endpointsPrefix}/leads${searchQuery}`,
    method: 'GET',
  },

  getSales: {
    url: (viewNumber: number) =>
      `${endpointsPrefix}/sales?view_number=${viewNumber}`,
    method: 'GET',
  },
  getCustomers: {
    url: (query: string) => `${endpointsPrefix}/customers${query}`,
    method: 'GET',
  },
  createMeeting: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/meetings`,
    method: 'POST',
  },
  listMeetings: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/meetings`,
    method: 'GET',
  },
  updateDealPipeline: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}`,
    method: 'PATCH',
  },
  listFeedback: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/feedback`,
    method: 'GET',
  },
  listDesiredUnit: {
    url: `${endpointsPrefix}/crm-units?view_number=1`,
    method: 'GET',
  },
  getCurrenciesList: {
    url: `/currencies?country_id=1`,
    method: 'GET',
  },
  getCustomerNationalities: {
    path: `/shopper/sales-nationalities`,
    method: 'GET',
  },
  getCustomerCities: {
    path: `/shopper/sales-cities`,
    method: 'GET',
  },
  getCustomerCountries: {
    path: `/shopper/sales-countries`,
    method: 'GET',
  },
  getCustomerOccupations: {
    path: `/shopper/sales-occupations`,
    method: 'GET',
  },
  qualifyCustomer: {
    url: `${endpointsPrefix}/customers`,
    method: 'POST',
  },
  attachementCustomer: {
    url: (customerId: string) =>
      `${endpointsPrefix}/customers/${customerId}/attach`,
    method: 'POST',
  },
  customerDetails: {
    url: (customerId: string) => `${endpointsPrefix}/customers/${customerId}`,
    method: 'GET',
  },
  getCustomerPipeline: {
    url: (customerId: string) =>
      `${endpointsPrefix}/pipelines?customer_source_id=${customerId}&view_number=2`,
    method: 'GET',
  },
  createSale: {
    url: (unit: string) => `${endpointsPrefix}/units/${unit}/sales`,
    method: 'POST',
  },
  updateSale: {
    url: (saleId: string, unitId: string) =>
      `${endpointsPrefix}/units/${unitId}/sales/${saleId}`,
    method: 'PATCH',
  },
  getCurrencies: {
    url: (sourceCurrency: string, currencies: string) =>
      `/currency-rates?source=${sourceCurrency}&currencies=${currencies}`,
    method: 'GET',
  },
};

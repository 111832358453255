async function downloadFile({
  fullURL,
  method = 'GET',
  body,
}: {
  fullURL: string;
  method?: string;
  body?: string;
}): Promise<Blob> {
  const url = new URL(fullURL);

  const response = await fetch(url.toString(), {
    method: method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
      )}`,
    },
    body,
  });

  if (!response.ok) {
    throw new Error(`Failed to download file: ${response.statusText}`);
  }

  if (response.status === 302) {
    throw new Error('File not found');
  }

  return response.blob();
}

export interface TriggerFileDownloadOptions {
  fullURL: string;
  filename?: string;
  extension?: string;
  method?: string;
  body?: string;
}
export async function triggerFileDownload({
  fullURL,
  filename,
  extension,
  method,
  body,
}: TriggerFileDownloadOptions) {
  try {
    const blob = await downloadFile({
      fullURL,
      method,
      body,
    });

    const fileUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = `${filename || 'file'}${extension ? '.' + extension : ''}`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(fileUrl);
    document.body.removeChild(a);
  } catch (error) {
    if (error instanceof Error) {
      console.log(error);
    }
    console.error('Error downloading file:', error);
    throw error;
  }
}

import { Route, UserRole } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import Destination from '../pages/destination/destination';
import MyDeals from '../pages/my-deals/my-deals';
import NewDeal from '../pages/new-deal/new-deal';
import DealDetailsPage from '../pages/deal-details/deal-details-page';
import Updates from '../pages/updates/updates';
import PrimaryUnits from '../pages/primary-units/primary-units';
import ResaleUnits from '../pages/resale-units/resale-units';
import { UnitDetails } from '../pages/unit-details/unit-details';
import ComparePropertiesPage from '../pages/compare-properties/compare-properties';
import DisqualifyLead from '../pages/disqualify-lead/disqualify-lead';
import MySales from '../pages/my-sales/my-sales';
import MyCustomers from '../pages/my-customers/my-customers';
import QualifyCustomer from '../pages/qualify-customer/qualify-customer';
import CustomerDetailsPage from '../pages/customer-details/customer-details-page';
import CreateSalePage from '../pages/create-sale/create-sale';

export const ROUTES: Record<string, Route> = {
  Login: {
    path: '/login',
    component: Login,
    public: true,
    role: UserRole.ALL,
  },
  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: true,
  },
  MyDeals: {
    path: '/',
    component: MyDeals,
    public: true,
  },
  NewDeal: {
    path: '/new-lead',
    component: NewDeal,
    public: true,
  },
  LeadPipelines: {
    path: '/leads/:dealId/pipelines',
    getPath: (leadId: string) => `/leads/${leadId}/pipelines`,
    component: DealDetailsPage,
    public: true,
  },
  LeadCreatePipelines: {
    path: '/leads/:dealId/pipelines/create',
    getPath: (leadId: string) => `/leads/${leadId}/pipelines/create`,
    component: DealDetailsPage,
    public: true,
  },
  DisqualifyLead: {
    path: '/leads/:leadId/disqualify-lead',
    getPath: (leadId: string) => `/leads/${leadId}/disqualify-lead`,
    component: DisqualifyLead,
    public: true,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: true,
  },
  PrimaryUnits: {
    path: '/primary-units',
    component: PrimaryUnits,
    public: true,
  },
  CompareProperties: {
    path: '/compare-properties',
    component: ComparePropertiesPage,
    public: true,
  },
  ResaleUnits: {
    path: '/resale-units',
    component: ResaleUnits,
    public: true,
  },
  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: string) => `/unit-details/${unitId}`,
    component: UnitDetails,
    public: true,
  },
  UnitDeal: {
    path: '/unit-details/:unitId/new-deal',
    getPath: (unitId: string) => `/unit-details/${unitId}/new-deal`,
    component: NewDeal,
    public: true,
  },
  MyCustomers: {
    path: '/customers',
    component: MyCustomers,
    public: true,
  },
  MySales: {
    path: '/sales',
    component: MySales,
    public: true,
  },
  QualifyCustomer: {
    path: '/qualify-customer/:destination/:leadId',
    getPath: (destination: string, leadId: string) =>
      `/qualify-customer/${destination}/${leadId}`,
    component: QualifyCustomer,
    public: true,
  },
  CustomerPipelines: {
    path: '/customers/:customerId/pipelines',
    getPath: (customerId: string) => `/customers/${customerId}/pipelines`,
    component: CustomerDetailsPage,
    public: true,
  },
  CustomerCreatePipelines: {
    path: '/customers/:customerId/pipelines/create',
    getPath: (customerId: string) =>
      `/customers/${customerId}/pipelines/create`,
    component: CustomerDetailsPage,
    public: true,
  },
  CreateSale: {
    path: '/customers/:customerId/pipelines/:pipelineId/create-sale',
    getPath: (customerId: string, pipelineId: string) =>
      `/customers/${customerId}/pipelines/${pipelineId}/create-sale`,
    component: CreateSalePage,
    public: true,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Loader, ScrollToTop } from '@orascom/common-components';
import { ROUTES } from '../api/routes';
import styles from './app.module.scss';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import {
  CompareUnitsProvider,
  Header,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { CurrencyContextProvider } from '@orascom/utils';
import { useEffect, useMemo, useState } from 'react';
import { UserInterface } from '@orascom/api-interfaces';
import { User as UserUtils } from '../utils/user';
import { storeTokenDetails } from '../utils/authentication';
import { ENDPOINTS } from '../api/endpoints';

export function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [user, setUser] = useState<UserInterface | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const showSidebar = !location.pathname.includes('login'); // TODO: change when users are added
  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      if (!route.public) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  useEffect(() => {
    if (token) {
      storeTokenDetails(token);
      searchParams.delete('token');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
    if (!user) {
      if (
        localStorage.getItem(
          process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
        )
      ) {
        UserUtils.getUserData()
          .then((res) => {
            setUser(res.data.user);
          })
          .catch(() => {
            navigate(ROUTES['Login'].path);
          })
          .finally(() => setLoadingUser(false));
      } else {
        setLoadingUser(false);
        navigate(ROUTES['Login'].path);
      }
    } else {
      setLoadingUser(false);
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
    }),
    [user, loadingUser]
  );

  if (loadingUser) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    // TODO : condition on user in classname
    <USER_CONTEXT.Provider value={userContextValue}>
      <CompareUnitsProvider>
        <CurrencyContextProvider
          defaultDollar
          getCurrenciesUrl={ENDPOINTS.getCurrencies.url}
        >
          <main
            className={`${
              showSidebar ? 'container container--flex' : 'container'
            }`}
          >
            {showSidebar && <Sidebar />}
            <ScrollToTop />
            <section className={styles['side-section']}>
              {showSidebar && (
                <Header
                  portal="Sales Man"
                  notificationPath={ROUTES['Notifications'].path}
                />
              )}
              <Routes>{availableRoutes}</Routes>
            </section>
          </main>
        </CurrencyContextProvider>
      </CompareUnitsProvider>
    </USER_CONTEXT.Provider>
  );
}

export default App;

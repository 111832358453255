import { Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import PipelineDetails from '../../components/pipeline-details/pipeline-details';
import {
  UserPipelineProvider,
  useUserPipelineContext,
} from '../../context/user-pipeline-context';
import styles from './deal-details.module.scss';

export function DealDetails() {
  const { t } = useTranslation();

  const { pendingCustomerData } = useUserPipelineContext();

  if (pendingCustomerData) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('leadDetails')}</h1>
      <PipelineDetails page="Lead" />
    </div>
  );
}

const DealDetailsPage = () => {
  return (
    <UserPipelineProvider>
      <DealDetails />
    </UserPipelineProvider>
  );
};

export default DealDetailsPage;

import {
  InstallmentFrequencyMapper,
  OptionValue,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import {
  Loader,
  LoadingButton,
  RangeSlider,
  SelectDropdown,
} from '@orascom/common-components';
import {
  Sales as SalesUtils,
  reservationPaymentOptions,
} from '../../utils/sale';

import {
  extractUniqueDurations,
  useCurrencyConverter,
  usePaymentPlanCalculator,
} from '@orascom/utils';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ROUTES } from '../../api/routes';
import {
  SaleCreationInput,
  SaleSubmission,
} from '../../definitions/interfaces/sales.interface';
import { useCreateSaleContext } from '../../pages/create-sale/create-sale-context';
import { Unit as UnitUtils } from '../../utils/unit';
import styles from './select-sale-payment.module.scss';
import * as yup from 'yup';
import GetConvertedCurrency from '../../utils/get-converted-currency';
import UnitDetailInfo from './unit-detail-info';

export interface SelectSalePaymentProps {
  pipelineSourceId?: string;
  customerSourceId?: string;
}
export function SelectSalePayment({
  pipelineSourceId,
  customerSourceId,
}: SelectSalePaymentProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);

  const { selectedUnit, goNext, goBack, setSaleId } = useCreateSaleContext();

  const [error, setError] = useState(false);

  const {
    onSelectDownPayment,
    onSelectFrequency,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
  } = usePaymentPlanCalculator();

  //payment terms with currency conversion
  const {
    unitPrice,
    unitPriceAfterDiscount,
    unitAfterDeliveryInstallmentAmount,
    unitBeforeDeliveryInstallmentAmount,
    unitClubhouseFees,
    unitClubhouseInstallments,
    unitMaintenanceFees,
    unitDownpaymentAmount,
    unitTotalUnitPrice,
  } = GetConvertedCurrency(selectedPaymentTerms);

  const { selectedCurrency } = useCurrencyConverter();

  useEffect(() => {
    if (!selectedUnit) return;
    setLoading(true);
    UnitUtils.getSalesManUnitPaymentTerms(selectedUnit?.id)
      .then((terms) => {
        setPaymentTerms(terms);
        setSelectedPaymentTerms(terms[0]);
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });
  }, [selectedUnit]);

  let downPaymentOptions: OptionValue[] = [];
  let installmentFreqOptions: OptionValue[] = [];

  const selectedDownPayment: {
    value: number;
    label: string;
  } | null = selectedPaymentTerms
    ? {
        label: `${selectedPaymentTerms.downpayment_percent}%`,
        value: selectedPaymentTerms.downpayment_percent,
      }
    : null;
  const installmentFreq = selectedPaymentTerms
    ? {
        label:
          InstallmentFrequencyMapper[
            selectedPaymentTerms.installment_frequency
          ],
        value: selectedPaymentTerms.installment_frequency,
      }
    : null;

  if (paymentTerms?.length > 0) {
    downPaymentOptions = paymentTerms.reduce((options: OptionValue[], term) => {
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${term.downpayment_percent}%`,
          value: term.downpayment_percent,
        });
      }
      return options;
    }, []);
    installmentFreqOptions = paymentTerms.reduce(
      (options: OptionValue[], term) => {
        if (!options.find((opt) => opt.value === term.installment_frequency)) {
          options.push({
            label: InstallmentFrequencyMapper[term.installment_frequency],
            value: term.installment_frequency,
          });
        }
        return options;
      },
      []
    );
  }

  if (loading) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <h2 className="orascom__title">No payment plan found for this unit</h2>
        <button
          onClick={goBack}
          className={`btn btn--navy ${styles['no-plans-back']}`}
        >
          {t('chooseAnotherUnit')}
        </button>
      </>
    );
  }

  const displayedPaymentDetails = { ...selectedPaymentTerms };

  const installmentDurations = extractUniqueDurations(paymentTerms);

  const validationSchema = yup.object({
    paidReservationAmount: yup
      .number()
      .typeError('Paid Reservation Amount must be a valid number.')
      .label('Paid Reservation Amount')
      .min(
        (selectedUnit?.price ?? 0) * 0.025 || 0,
        `Paid Reservation Amount must be at least 2.5% of the unit price.`
      )
      .max(
        selectedPaymentTerms?.downpayment_amount || 0,
        'Paid Reservation Amount cannot exceed the downpayment value.'
      )
      .required(),
    reservationPaymentMethod: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .transform((value, originalValue) => {
        return originalValue ?? undefined;
      })
      .label('Reservation Payment Method')
      .required(),
  });

  const submitHandler = (values: SaleCreationInput) => {
    if (!selectedUnit || !customerSourceId || !pipelineSourceId || submitting)
      return;

    setSubmitting(true);
    const mappedData: SaleSubmission = {
      pipeline_source_id: pipelineSourceId,
      customer_source_id: customerSourceId,
      unit_source_id: selectedUnit?.id.toString(),
      paymentplan_source_id: selectedPaymentTerms?.paymentplan_id ?? '',
      reservation_payment_method:
        values.reservationPaymentMethod?.value.toString() ?? '',
      reservation_amount: values.paidReservationAmount ?? '',
      selling_price: selectedPaymentTerms?.total_unit_price ?? 0,
    };
    SalesUtils.createSale(mappedData, selectedUnit?.id.toString())
      .then((res) => {
        const saleId = res.data?.data?.sale_source_id;
        if (saleId) setSaleId(saleId);
        goNext();
        toast.success('Sale created successfully');
      })
      .catch((err) => {
        if (Array.isArray(err)) {
          if (typeof err[0] === 'string') {
            toast.error(err[0]);
          }
        } else {
          toast.error('Failed to create sale');
        }
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className={styles['wrapper']}>
      <div className={styles['payment']}>
        <Formik
          initialValues={{
            paidReservationAmount: '',
            reservationPaymentMethod: reservationPaymentOptions[0],
          }}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
        >
          {({ errors, setFieldValue, touched, values }) => (
            <Form>
              <p>{t('paymentAndReservation')}</p>
              {selectedUnit && (
                <PropertyCard
                  unit={selectedUnit}
                  portal="sales-man"
                  handleRemove={undefined}
                  listingView="list"
                  unitDetailsPath={ROUTES['UnitDetails'].getPath}
                  hideCompare
                  openDetailsInNewTab
                  isInCreateSale
                />
              )}
              <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
                <label>{t('downPayment')}</label>
                <div className={styles['select-wrapper']}>
                  <SelectDropdown
                    options={downPaymentOptions}
                    placeholder={t('downPayment')}
                    onChange={(val) => {
                      onSelectDownPayment(val?.value as number, paymentTerms);
                    }}
                    selectedOption={selectedDownPayment}
                    className="select"
                  />
                  {selectedPaymentTerms && (
                    <span className={styles['select-value']}>
                      {Math.round(unitDownpaymentAmount ?? 0).toLocaleString()}{' '}
                      {selectedCurrency}
                    </span>
                  )}
                </div>
              </div>
              <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
                <label>{t('installmentFrequency')}</label>
                <SelectDropdown
                  options={installmentFreqOptions}
                  placeholder={t('installmentFrequency')}
                  onChange={(val) => {
                    onSelectFrequency(val?.value as number, paymentTerms);
                  }}
                  selectedOption={installmentFreq}
                />
              </div>
              <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
                <div className={styles['flex']}>
                  <label>{t('installmentDuration')}</label>
                  <h5>
                    {displayedPaymentDetails?.installment_durationin_years}{' '}
                    {t('years')}
                  </h5>
                </div>
                <RangeSlider
                  className={styles['range']}
                  sliderValues={installmentDurations}
                  inputValue={
                    selectedPaymentTerms?.installment_durationin_years
                  }
                  onChange={(val) => {
                    onSelectDuration(val, paymentTerms);
                  }}
                  sliderWrapperProps={{ className: styles['range-wrapper'] }}
                />
              </div>
              <p>{t('reservationFee')}</p>
              <div className="form__input">
                <label htmlFor="paidReservationAmount">
                  {t('paidReservationAmount')}
                </label>
                <div className="form__input-currency">
                  <Field
                    id="paidReservationAmount"
                    name="paidReservationAmount"
                    type="text"
                    className={
                      errors.paidReservationAmount &&
                      touched.paidReservationAmount
                        ? 'error'
                        : ''
                    }
                    placeholder={t('writeAmountHere')}
                  />
                  <span>{selectedPaymentTerms?.currency}</span>
                </div>
                <ErrorMessage
                  name="paidReservationAmount"
                  component="div"
                  className="form__error"
                />
              </div>
              <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
                <label>{t('reservationPaymentMethod')}</label>
                <div className={styles['select-wrapper']}>
                  <SelectDropdown
                    name="reservationPaymentMethod"
                    options={reservationPaymentOptions}
                    placeholder={t('reservationPaymentMethod')}
                    onChange={(val) => {
                      setFieldValue('reservationPaymentMethod', val);
                    }}
                    selectedOption={reservationPaymentOptions.find(
                      (option) =>
                        option.value === values.reservationPaymentMethod?.value
                    )}
                    className="select"
                    defaultValue={reservationPaymentOptions[0]}
                  />
                  <ErrorMessage
                    name="reservationPaymentMethod"
                    component="div"
                    className="form__error"
                  />
                </div>
              </div>
              <div className={styles['submit']}>
                <LoadingButton
                  type="submit"
                  className="btn btn--navy"
                  loading={submitting}
                  // disabled={!isValid}
                >
                  {t('next')}
                </LoadingButton>
                <button onClick={goBack} className={styles['cancel-btn']}>
                  {t('back')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className={styles['unit']}>
        <h3>{t('paymentPlan')}</h3>
        {paymentTerms && (
          <div className={styles['unit__details-wrapper']}>
            <div className={styles['unit__details']}>
              <p className={styles['unit__label']}>{t('unitPrice')}</p>
              <UnitDetailInfo
                label={t('originalUnitPrice')}
                result={`${Math.round(
                  unitPrice ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
              <UnitDetailInfo
                label={t('discount')}
                result={`- ${Math.round(
                  displayedPaymentDetails.discount_amount ?? 0
                ).toLocaleString()} (${
                  displayedPaymentDetails.discount_percent
                })%`}
                resultType="discount"
              />
              <UnitDetailInfo
                label={t('priceAfterDiscount')}
                result={`${Math.round(
                  unitPriceAfterDiscount ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
                resultType="bold"
              />
            </div>

            <hr />

            <div className={styles['unit__details']}>
              <p className={styles['unit__label']}>{t('unitInstallments')}</p>
              <UnitDetailInfo
                label={`${t('beforeDeliveryInstallments')} (${
                  displayedPaymentDetails.total_number_of_installments_before_delivery
                })`}
                result={`${Math.round(
                  unitBeforeDeliveryInstallmentAmount ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
              <UnitDetailInfo
                label={`${t('afterDeliveryInstallments')} (${
                  displayedPaymentDetails.total_number_of_installments_after_delivery
                })`}
                result={`${Math.round(
                  unitAfterDeliveryInstallmentAmount ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
              <UnitDetailInfo
                label={t('frequency')}
                result={`${
                  InstallmentFrequencyMapper[
                    displayedPaymentDetails.installment_frequency ?? ''
                  ]
                }`}
              />
              <UnitDetailInfo
                label={t('duration')}
                result={`${
                  displayedPaymentDetails.installment_durationin_years
                } ${t('years')}`}
              />
            </div>

            <hr />

            <div className={styles['unit__details']}>
              <p className={styles['unit__label']}>
                {t('clubhouseAndMaintenance')}
              </p>
              <UnitDetailInfo
                label={t('clubhouseFees')}
                result={`${Math.round(
                  unitClubhouseFees ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
              <UnitDetailInfo
                label={t('maintenanceFees')}
                result={`${Math.round(
                  unitMaintenanceFees ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
              <UnitDetailInfo
                label={`${t('clubhouseInstallments')} (${
                  displayedPaymentDetails.clubhouse_fees_installment_count
                })`}
                result={`${Math.round(
                  unitClubhouseInstallments ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
              />
            </div>

            <hr />

            <div className={styles['unit__details']}>
              <p className={styles['unit__label']}>{t('totalPrice')}</p>
              <UnitDetailInfo
                label={t('downpayment')}
                result={`${Math.round(
                  unitDownpaymentAmount ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
                resultType="bold"
              />

              <UnitDetailInfo
                label={t('totalUnitPrice')}
                result={`${Math.round(
                  unitTotalUnitPrice ?? 0
                ).toLocaleString()} ${selectedCurrency}`}
                resultType="bold"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectSalePayment;

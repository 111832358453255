import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './lead-stages.module.scss';

interface PendingReservationProps {
  meetingDate: string;
  customerId?: string;
  leadId?: string;
  pipeline_source_id: string;
  lintTo: string;
  linkText: string;
}
const PendingReservation = ({
  meetingDate,
  customerId,
  leadId,
  pipeline_source_id,
  lintTo,
  linkText,
}: PendingReservationProps) => {
  const { t } = useTranslation();
  const inputFormat = 'yyyy-MM-dd HH:mm:ss';
  const parsedDate = parse(meetingDate, inputFormat, new Date());
  const outputFormat = 'EEE, d MMM yyyy';

  // Format the parsed Date object into the desired format
  const formattedDate = format(parsedDate, outputFormat);

  return (
    <div className={styles['pending-reservation']}>
      <p className={styles['title']}>{t('closeDeal')}</p>

      <div className={styles['info-wrapper']}>
        <div className={styles['info']}>
          <p className={styles['info__title']}>{t('meetingDate')}</p>
          <div className={styles['info__value']}>{formattedDate}</div>
        </div>
        {/* <div>
          <div className={styles['info__title']}>{t('status')}</div>
          <div className={styles['info__value-wrapper']}>
            <div className={styles['info__circle']} />
            <div className={styles['info__value']}>
              {t('pendingReservation')}
            </div>
          </div>
        </div> */}
      </div>

      <div className={styles['actions']}>
        <Link
          className={`btn btn--navy ${styles['cta-link']}`}
          to={lintTo}
          state={{ customerId, pipelineSourceId: pipeline_source_id, leadId }}
        >
          {linkText}
        </Link>
      </div>
      <hr className={styles['line']} />
    </div>
  );
};

export default PendingReservation;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomerDetails as CustomerDetailsInterface } from '../definitions/interfaces/customers.interface';
import {
  LeadDetails as LeadDetailsInterface,
  PipelineDetails as PipelineDetailsInterface,
} from '../definitions/interfaces/lead-stages.interface';
import { Customer as CustomerApi } from '../utils/customer';
import { Deal as LeadApi } from '../utils/deal.utils';

const useUserAndPipelines = (customerId?: string, leadId?: string) => {
  const [pipelines, setPipelines] = useState<PipelineDetailsInterface[]>([]);

  const [lead, setLead] = useState<LeadDetailsInterface>();
  const [customer, setCustomer] = useState<CustomerDetailsInterface>();

  const [pendingCustomerData, setPendingCustomerData] = useState(true);

  const fetchLeadAndPipelines = () => {
    if (!leadId) return;
    return Promise.all([
      LeadApi.getLeadDetails(leadId),
      LeadApi.getPipelineDetails(leadId),
    ])
      .then((res) => {
        setLead(res[0]);
        setPipelines(() => res[1]);
      })
      .catch(() => toast.error('Failed to fetch lead and pipelines data'))
      .finally(() => {
        setPendingCustomerData(false);
      });
  };

  const fetchCustomerAndPipelines = () => {
    if (!customerId) return;
    return Promise.all([
      CustomerApi.getCustomerDetails(customerId),
      CustomerApi.getCustomerPipeline(customerId),
    ])
      .then((res) => {
        setCustomer(res[0]);
        setPipelines(() => res[1]);
      })
      .catch(() => toast.error('Failed to fetch customer and pipelines data'))
      .finally(() => {
        setPendingCustomerData(false);
      });
  };

  useEffect(() => {
    // Customer and lead pages are distinct, so we fetch data accordingly
    if (customerId) {
      fetchCustomerAndPipelines();
    } else if (leadId) {
      fetchLeadAndPipelines();
    }
    // eslint-disable-next-line
  }, [customerId, leadId]);

  return {
    customer,
    lead,
    setCustomer,
    pipelines,
    setPipelines,
    pendingCustomerData,
    setPendingCustomerData,
  };
};

export default useUserAndPipelines;

import { UnitInterface } from '@orascom/api-interfaces';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

enum CreateSaleStepsEnum {
  Unit = 1,
  Payment = 2,
  Reservation = 3,
  Success = 4,
}

interface CreateSaleContextInterface {
  saleId?: string;
  step: CreateSaleStepsEnum;
  goNext: () => void;
  goBack: () => void;
  selectedUnit: UnitInterface | undefined;
  setSaleId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedUnit: Dispatch<SetStateAction<UnitInterface | undefined>>;
}
const CreateSaleContext = createContext<CreateSaleContextInterface>({
  saleId: undefined,
  step: CreateSaleStepsEnum.Unit,
  goNext: () => {
    throw new Error('goNext must be implemented');
  },
  goBack: () => {
    throw new Error('goBack must be implemented');
  },
  selectedUnit: undefined,
  setSelectedUnit: () => {
    throw new Error('setSelectedUnit must be implemented');
  },
  setSaleId: () => {
    throw new Error('setSaleId must be implemented');
  },
});

interface CreateSaleProviderProps extends PropsWithChildren {}
const CreateSaleProvider = ({ children }: CreateSaleProviderProps) => {
  const [step, setStep] = useState<CreateSaleStepsEnum>(
    CreateSaleStepsEnum.Unit
  );
  const [selectedUnit, setSelectedUnit] = useState<UnitInterface | undefined>();

  const [saleId, setSaleId] = useState<string | undefined>();

  const goNext = () => {
    if (step >= CreateSaleStepsEnum.Success) return;
    setStep((s) => s + 1);
  };

  const goBack = () => {
    if (step <= 1) return;
    setStep((s) => s - 1);
  };

  return (
    <CreateSaleContext.Provider
      value={{
        step,
        goNext,
        goBack,
        selectedUnit,
        setSelectedUnit,
        saleId,
        setSaleId,
      }}
    >
      {children}
    </CreateSaleContext.Provider>
  );
};

const useCreateSaleContext = () => {
  const context = useContext(CreateSaleContext);
  return context;
};

export {
  CreateSaleContext,
  CreateSaleProvider,
  CreateSaleStepsEnum,
  useCreateSaleContext,
};

import { OptionValue, UnitInterface } from '@orascom/api-interfaces';
import { LoadingButton, SelectDropdown } from '@orascom/common-components';
import { format } from 'date-fns';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { UpdatePipelineInputInterface } from '../../definitions/interfaces/deals.interface';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { Value } from '../initial-meeting/initial-meeting';
import { buildUpdatePipelinePayload } from './deal-close-utils';
import styles from './deal-close.module.scss';
import useDestinations from '../../hooks/useDestinations';
import { Unit } from '../../utils/unit';

export function DealClose() {
  const { t } = useTranslation();
  const { selectedPipeline, setPipelines, refetchPipelines } =
    useUserPipelineContext();
  const [calledUnits, setCalledUnits] = useState<UnitInterface[]>();

  const desiredUnits = calledUnits?.map((unit: UnitInterface) => ({
    label: unit.name,
    value: unit.id,
  }));

  const [selectedDate, setSelectedDate] = useState<Value>(null);
  const { dealId, customerId } = useParams();

  const [loadingUnits, setLoadingUnits] = useState(true);
  const [closingDeal, setClosingDeal] = useState(false);
  const [fetchingPipelines, setFetchingPipelines] = useState(false);

  const { destinationsOptions, pendingDestinations } = useDestinations();

  const [chosenDestination, setChosenDestination] = useState<OptionValue>({
    label: '',
    value: '',
  });

  const [searchedInput, setSearchedInput] = useState('');

  const leadDestination = useMemo(
    () => ({
      label: selectedPipeline?.destination_name || '',
      value: selectedPipeline?.destination_slug || '',
    }),
    [selectedPipeline]
  );

  const minDate = new Date();
  minDate.setDate(minDate.getDate());
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 7);

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchedInput.length == 0) {
      if (chosenDestination.value !== '') {
        params.append('destinations[0]', chosenDestination!.value!.toString());
        params.set('on_resale', '0');
        params.set('page', '1');
        params.set('per_page', '12');
      } else {
        if (leadDestination) {
          params.append('destinations[0]', leadDestination!.value!);
          params.set('on_resale', '0');
          params.set('page', '1');
          params.set('per_page', '12');
        }
      }
      if (params.size > 0) {
        Unit.getSalesManUnits(1, 100, params)
          .then((res) => {
            setCalledUnits(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingUnits(false));
      }
    }
  }, [chosenDestination, leadDestination, searchedInput]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchedInput.length >= 2) {
      if (chosenDestination.value !== '') {
        params.append('name', searchedInput);
        params.append('destinations[0]', chosenDestination!.value!.toString());
        params.set('on_resale', '0');
        params.set('page', '1');
        params.set('per_page', '12');
      } else {
        if (leadDestination) {
          params.append('name', searchedInput);
          params.append('destinations[0]', leadDestination!.value!);
          params.set('on_resale', '0');
          params.set('page', '1');
          params.set('per_page', '12');
        }
      }
      if (params.size > 0) {
        Unit.getSalesManUnits(1, 100, params)
          .then((res) => {
            setCalledUnits(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingUnits(false));
      }
    }
  }, [searchedInput]);

  const schema = yup.object({
    desiredUnit: yup.object().nullable().required(t('required')),
    destinationSlug: yup.string().label(t('destination')).nullable().required(),
    selectedDate: yup.string().nullable().required(t('required')),
  });

  const submitHandler = (values: UpdatePipelineInputInterface) => {
    if (!selectedPipeline) return;
    setClosingDeal(true);
    const timestampMilliseconds = values.selectedDate
      ? new Date(values.selectedDate).getTime()
      : 0;

    const data = buildUpdatePipelinePayload({
      leadId: dealId ?? '',
      customerId: customerId ?? '',
      pipeline: selectedPipeline,
      timestampMilliseconds,
      unit_id: String(values.desiredUnit?.value),
      destination_slug:
        values.destinationSlug ?? selectedPipeline.destination_slug,
    });

    DealUtils.updateDealPipeline(selectedPipeline.pipeline_source_id, data)
      .then(() => {
        setClosingDeal(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        setFetchingPipelines(false);

        toast.success('Pipeline closed successfully');
      })
      .catch(() => {
        toast.error('Error closing pipeline');
      })
      .finally(() => {
        setClosingDeal(false);
        setFetchingPipelines(false);
      });
  };

  const submitLoadingText = fetchingPipelines
    ? t('configuringPipeline..')
    : t('closingDeal..');

  return (
    <div className={styles['deal-close']}>
      <p className={styles['title']}>{t('closeDeal')}</p>
      <p className={styles['description']}>{t('closeDealDescription')}</p>

      <Formik
        initialValues={{
          desiredUnit: null as OptionValue | null,
          selectedDate: '',
          destinationSlug: selectedPipeline?.destination_slug ?? '',
        }}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ errors, setFieldValue, values, touched }) => {
          return (
            <Form className={styles['form']}>
              <div className={styles['select-section']}>
                <div className="form__input">
                  <label>{t('destination')}</label>
                  <SelectDropdown
                    name="destinationSlug"
                    invalid={
                      touched.destinationSlug && !!errors.destinationSlug
                    }
                    className={`${styles['select']}`}
                    options={destinationsOptions}
                    isLoading={pendingDestinations}
                    placeholder={t('selectPlaceholder')}
                    onChange={(val) => {
                      setFieldValue('destinationSlug', val?.value);
                      setChosenDestination({
                        label: val ? val?.label : '',
                        value: val ? val?.value : '',
                      });
                      setFieldValue('desiredUnit', null);
                      setLoadingUnits(true);
                    }}
                    defaultValue={leadDestination}
                    selectedOption={
                      destinationsOptions.find(
                        (option) => option.value === values.destinationSlug
                      ) || leadDestination
                    }
                  />

                  <ErrorMessage
                    name="destinationSlug"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className={styles['desired-units-select']}>
                  <label htmlFor="desiredUnit">{t('chooseUnit')}</label>
                  <SelectDropdown
                    isLoading={loadingUnits}
                    name="desiredUnit"
                    className={`${styles['select']}`}
                    invalid={!!errors.desiredUnit && touched.desiredUnit}
                    options={desiredUnits}
                    placeholder={t('selectPlaceholder')}
                    onChange={(val) => {
                      setFieldValue('desiredUnit', val);
                    }}
                    selectedOption={values.desiredUnit || null}
                    isClearable
                    onInputChange={(val) => {
                      setSearchedInput(val);
                    }}
                  />

                  <ErrorMessage
                    name="desiredUnit"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className={styles['meeting-date']}>
                  <label htmlFor="selectedDate">{t('selectDate')}</label>
                  <div className={styles['modal-wrapper']}>
                    <button
                      type="button"
                      onClick={toggleDialog}
                      className={styles['btn-outline']}
                    >
                      <p>
                        {selectedDate
                          ? format(new Date(selectedDate), 'dd MMMM yyyy')
                          : t('selectDate')}
                      </p>
                      <img
                        style={{ height: 'auto', rotate: '-90deg' }}
                        src={ChevronLeft}
                        alt=""
                        role="presentation"
                      />
                    </button>
                    <dialog ref={dialogRef} className={styles['modal']}>
                      <Calendar
                        value={values.selectedDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date) => {
                          setSelectedDate(date as Value);
                          setFieldValue('selectedDate', date);
                          toggleDialog();
                        }}
                        view={'month'}
                        prevLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={LeftArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                        nextLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={RightArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                      />
                    </dialog>
                  </div>
                  <ErrorMessage
                    name="selectedDate"
                    component="div"
                    className="form__error"
                  />
                </div>
              </div>
              <LoadingButton
                loading={closingDeal || fetchingPipelines}
                type="submit"
                loadingText={submitLoadingText}
                spinnerSize="sm"
                className={`btn btn--navy ${styles['close-btn']}`}
              >
                {t('scheduleClosing')}
              </LoadingButton>
              <hr className={styles['line']} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default DealClose;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './my-deals.module.scss';
import { debounce, Loader } from '@orascom/common-components';
import { DealInterface } from '../../definitions/interfaces/deals.interface';
import plusIcon from '../../assets/icons/plus--white.svg';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { CommonEventParameters } from '@orascom/utils';
import { analyticsCreateLeadCustomEvent } from '../../utils/analytics-events';
import { ROUTES } from '../../api/routes';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { format } from 'date-fns';
import searchIcon from '../../assets/icons/search.svg';
import FilterPopup from '../../components/filter-popup/filter-popup';
import DateFilterPopup from '../../components/date-filter-popup/date-filter-popup';
import RemoveIcon from '../../assets/icons/close.svg';

export function MyDeals() {
  const { t } = useTranslation();
  // For activeTab (1 = digital leads) (2 = indirect leads) (3 = direct leads) (4 = no answer leads) (5 = all leads)
  const tabs = { digital: 1, indirect: 2, direct: 3, noAnswer: 4, all: 5 };
  const [activeTab, setActiveTab] = useState(5);
  const tlsCommentReplacmentHeader: Record<number, string> = {
    1: 'tlsComment',
    2: 'ambassadorName',
    3: 'createdOn',
    4: 'followUpDate',
    5: 'leadSource',
  };
  const [deals, setDeals] = useState<DealInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [filters, setFilters] = useState<Record<string, string[]>>({});

  const handleFilterChange = (column: string, selectedValues: string[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: selectedValues,
    }));
  };

  const [resetEngDate, setResetEngDate] = useState(true);
  const [resetExpDate, setResetExpDate] = useState(true);
  const [resetCreatedDate, setResetCreatedDate] = useState(true);
  const [resetFollowupDate, setResetFollowupDate] = useState(true);

  const [dateFilters, setDateFilters] = useState<{
    engagementDate?: { startDate: Date | null; endDate: Date | null };
    leadExpirationDate?: { startDate: Date | null; endDate: Date | null };
    createdOn?: { startDate: Date | null; endDate: Date | null };
    followupDate?: { startDate: Date | null; endDate: Date | null };
  }>({});

  const resetDateFilters = () => {
    setResetEngDate(!resetEngDate);
    setResetExpDate(!resetExpDate);
    setResetCreatedDate(!resetCreatedDate);
    setResetFollowupDate(!resetFollowupDate);
    setDateFilters({
      engagementDate: { startDate: null, endDate: null },
      leadExpirationDate: { startDate: null, endDate: null },
      createdOn: { startDate: null, endDate: null },
      followupDate: { startDate: null, endDate: null },
    });
  };

  const filteredDeals = deals
    ?.filter((deal) =>
      Object.keys(filters).every((key) =>
        filters[key].length === 0
          ? true
          : filters[key].some(
              (filterValue) => String(deal[key]) === filterValue
            )
      )
    )
    .filter((deal) => {
      if (
        dateFilters.engagementDate?.startDate &&
        dateFilters.engagementDate?.endDate
      ) {
        const engagementDate = deal.last_engagement_date_timestamp
          ? new Date(deal.last_engagement_date_timestamp * 1000)
          : null;

        if (
          !engagementDate ||
          engagementDate < dateFilters.engagementDate.startDate! ||
          engagementDate > dateFilters.engagementDate.endDate!
        ) {
          return false;
        }
      }

      if (
        dateFilters.leadExpirationDate?.startDate &&
        dateFilters.leadExpirationDate?.endDate
      ) {
        const leadExpirationDate = deal.lead_expiration_date
          ? new Date(deal.lead_expiration_date)
          : null;

        if (
          !leadExpirationDate ||
          leadExpirationDate < dateFilters.leadExpirationDate.startDate! ||
          leadExpirationDate > dateFilters.leadExpirationDate.endDate!
        ) {
          return false;
        }
      }

      if (dateFilters.createdOn?.startDate && dateFilters.createdOn?.endDate) {
        const leadCreatedDate = deal.source_created_at_timestamp
          ? new Date(deal.source_created_at_timestamp * 1000)
          : null;

        if (
          !leadCreatedDate ||
          leadCreatedDate < dateFilters.createdOn.startDate! ||
          leadCreatedDate > dateFilters.createdOn.endDate!
        ) {
          return false;
        }
      }

      if (
        dateFilters.followupDate?.startDate &&
        dateFilters.followupDate?.endDate
      ) {
        const followupDate = deal.followup_date_timestamp
          ? new Date(deal.followup_date_timestamp * 1000)
          : null;

        if (
          !followupDate ||
          followupDate < dateFilters.followupDate.startDate! ||
          followupDate > dateFilters.followupDate.endDate!
        ) {
          return false;
        }
      }

      return true;
    });

  const currentItems = filteredDeals?.slice(indexOfFirstItem, indexOfLastItem);
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();
  const [numberSearched, setNumberSearched] = useState('');
  const searchDeals = (value: string) => {
    if (!value.length) {
      setTableLoading(true);
      setNumberSearched('');
    }
    setTableLoading(true);
    setNumberSearched(value);
  };
  const debouncedOnChange = debounce(searchDeals, 1000);

  const handleDateFormat = (value: string) => {
    const date = new Date(value);
    const formattedDate = format(date, 'dd MMMM yyyy');

    return formattedDate;
  };

  const handleTabBtn = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setCurrentPage(1);
      setTableLoading(true);
    }
  };

  // Todo: all this will be dynamic after integration
  const analyticsEventParams: CommonEventParameters = {
    userId: '1',
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };

  useEffect(() => {
    const filterparamObject = '';
    const searchQuery = new URLSearchParams(filterparamObject);
    searchQuery.append('view_number', String(activeTab));
    searchQuery.append('search', String(numberSearched));

    const getLeadParam = '';
    const leadQuery = new URLSearchParams(String(getLeadParam));
    leadQuery.append('view_number', String(activeTab));

    DealUtils.getLeads(
      numberSearched.length
        ? `/search?${searchQuery.toString()}`
        : `?${leadQuery.toString()}`
    )
      .then((res) => {
        setDeals(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [activeTab, numberSearched]);

  useEffect(() => {
    setFilters({});
    resetDateFilters();
  }, [activeTab]);

  const uniqueOptions = (key: string) => {
    return Array.from(new Set(deals.map((deal) => deal[key]))).map((value) => ({
      label: value,
      value: value,
    }));
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myLeads')}</h1>
      <div className={styles['tabs']}>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.all)}
          className={`${styles['tab']} ${
            activeTab === tabs.all && styles['tab--active']
          }`}
        >
          {t('allLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.digital);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.digital && styles['tab--active']
          }`}
        >
          {t('digitalLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.indirect)}
          className={`${styles['tab']} ${
            activeTab === tabs.indirect && styles['tab--active']
          }`}
        >
          {t('indirectLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.direct)}
          className={`${styles['tab']} ${
            activeTab === tabs.direct && styles['tab--active']
          }`}
        >
          {t('directLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.noAnswer)}
          className={`${styles['tab']} ${
            activeTab === tabs.noAnswer && styles['tab--active']
          }`}
        >
          {t('noAnswerLeads')}
        </button>
      </div>
      <div className={styles['sub-header']}>
        <div className={styles['search-input']}>
          <img src={searchIcon} alt="" role="presentation" />
          <input
            type="text"
            placeholder={t('searchLead')}
            onChange={(e) => debouncedOnChange(e.target.value)}
          />
        </div>
        <Link
          to={ROUTES['NewDeal'].path}
          className={styles['deal-anchor']}
          onClick={() => analyticsCreateLeadCustomEvent(analyticsEventParams)}
        >
          <img src={plusIcon} alt="plus-icon" />
          {t('newLead')}
        </Link>
      </div>
      <div className={styles['applied-filters']}>
        {Object.entries(filters)
          .flatMap(([key, values]) =>
            values.map((value) => ({
              key,
              value,
            }))
          )
          .map(({ key, value }) => (
            <div key={`${key}-${value}`} className={styles['filter-item']}>
              {value}
              <button
                onClick={() =>
                  handleFilterChange(
                    key,
                    filters[key].filter((item) => item !== value)
                  )
                }
                className={styles['remove-filter']}
              >
                <img src={RemoveIcon} alt="remove" />
              </button>
            </div>
          ))}

        {dateFilters.engagementDate?.startDate &&
          dateFilters.engagementDate?.endDate && (
            <div className={styles['filter-item']}>
              {t('engagementDate')}:{' '}
              {`${format(
                dateFilters.engagementDate.startDate,
                'dd MMM yyyy'
              )} - ${format(
                dateFilters.engagementDate.endDate,
                'dd MMM yyyy'
              )}`}
              <button
                onClick={() => {
                  setResetEngDate(!resetEngDate);
                  setDateFilters((prev) => ({
                    ...prev,
                    engagementDate: { startDate: null, endDate: null },
                  }));
                }}
                className={styles['remove-filter']}
              >
                <img src={RemoveIcon} alt="remove" />
              </button>
            </div>
          )}
        {dateFilters.leadExpirationDate?.startDate &&
          dateFilters.leadExpirationDate?.endDate && (
            <div className={styles['filter-item']}>
              {t('leadExpirationDate')}:{' '}
              {`${format(
                dateFilters.leadExpirationDate.startDate,
                'dd MMM yyyy'
              )} - ${format(
                dateFilters.leadExpirationDate.endDate,
                'dd MMM yyyy'
              )}`}
              <button
                onClick={() => {
                  setResetExpDate(!resetExpDate);
                  setDateFilters((prev) => ({
                    ...prev,
                    leadExpirationDate: { startDate: null, endDate: null },
                  }));
                }}
                className={styles['remove-filter']}
              >
                <img src={RemoveIcon} alt="remove" />
              </button>
            </div>
          )}
        {dateFilters.createdOn?.startDate && dateFilters.createdOn?.endDate && (
          <div className={styles['filter-item']}>
            {t('leadExpirationDate')}:{' '}
            {`${format(
              dateFilters.createdOn.startDate,
              'dd MMM yyyy'
            )} - ${format(dateFilters.createdOn.endDate, 'dd MMM yyyy')}`}
            <button
              onClick={() => {
                setResetCreatedDate(!resetCreatedDate);
                setDateFilters((prev) => ({
                  ...prev,
                  createdOn: { startDate: null, endDate: null },
                }));
              }}
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        )}

        {dateFilters.followupDate?.startDate &&
          dateFilters.followupDate?.endDate && (
            <div className={styles['filter-item']}>
              {t('followupDate')}:{' '}
              {`${format(
                dateFilters.followupDate.startDate,
                'dd MMM yyyy'
              )} - ${format(dateFilters.followupDate.endDate, 'dd MMM yyyy')}`}
              <button
                onClick={() => {
                  setResetFollowupDate(!resetFollowupDate);
                  setDateFilters((prev) => ({
                    ...prev,
                    followupDate: { startDate: null, endDate: null },
                  }));
                }}
                className={styles['remove-filter']}
              >
                <img src={RemoveIcon} alt="remove" />
              </button>
            </div>
          )}
        {Object.values(filters).some((values) => values.length > 0) ||
        (dateFilters.engagementDate?.startDate &&
          dateFilters.engagementDate?.endDate) ||
        (dateFilters.leadExpirationDate?.startDate &&
          dateFilters.leadExpirationDate?.endDate) ||
        (dateFilters.followupDate?.startDate &&
          dateFilters.followupDate?.endDate) ? (
          <button
            onClick={() => {
              setFilters({});
              resetDateFilters();
            }}
            className={styles['reset-filters']}
          >
            {t('reset')}
          </button>
        ) : null}
      </div>

      <div className={tableLoading ? '' : styles['table']}>
        {tableLoading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length ? (
          <table>
            <thead>
              <tr>
                <th>
                  <div className={styles['header-filter']}>{t('name')}</div>
                </th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('mobileNumber')}
                  </div>
                </th>
                <th>{t('owner')}</th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('leadExpDate')}
                    <DateFilterPopup
                      reset={resetExpDate}
                      onFilterChange={(startDate, endDate) =>
                        setDateFilters((prev) => ({
                          ...prev,
                          leadExpirationDate: { startDate, endDate },
                        }))
                      }
                    />
                  </div>
                </th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('leadExpStatus')}
                    <FilterPopup
                      column="lead_expiration_status"
                      selectedValues={filters['lead_expiration_status'] || []}
                      onFilterChange={handleFilterChange}
                      options={uniqueOptions('lead_expiration_status')}
                    />
                  </div>
                </th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('channel')}
                    <FilterPopup
                      column="channel"
                      selectedValues={filters['channel'] || []}
                      onFilterChange={handleFilterChange}
                      options={uniqueOptions('channel')}
                    />
                  </div>
                </th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('lastEngDate')}
                    <DateFilterPopup
                      reset={resetEngDate}
                      onFilterChange={(startDate, endDate) =>
                        setDateFilters((prev) => ({
                          ...prev,
                          engagementDate: { startDate, endDate },
                        }))
                      }
                    />
                  </div>
                </th>
                {activeTab === tabs.indirect && <th>{t('broker')}</th>}
                <th>
                  <div className={styles['header-filter']}>
                    {t(tlsCommentReplacmentHeader[activeTab])}
                    {activeTab === 3 && (
                      <DateFilterPopup
                        reset={resetCreatedDate}
                        onFilterChange={(startDate, endDate) =>
                          setDateFilters((prev) => ({
                            ...prev,
                            createdOn: { startDate, endDate },
                          }))
                        }
                      />
                    )}
                    {activeTab === 4 && (
                      <DateFilterPopup
                        reset={resetFollowupDate}
                        onFilterChange={(startDate, endDate) =>
                          setDateFilters((prev) => ({
                            ...prev,
                            followupDate: { startDate, endDate },
                          }))
                        }
                      />
                    )}
                    {activeTab === 5 && (
                      <FilterPopup
                        column="lead_source"
                        onFilterChange={handleFilterChange}
                        options={uniqueOptions('lead_source')}
                        selectedValues={filters['lead_source'] || []}
                      />
                    )}
                  </div>
                </th>
                <th>
                  <div className={styles['header-filter']}>
                    {t('feedback')}
                    <FilterPopup
                      column="sales_feedback"
                      onFilterChange={handleFilterChange}
                      options={uniqueOptions('sales_feedback')}
                      selectedValues={filters['sales_feedback'] || []}
                    />
                  </div>
                </th>
                <th>{t('action')}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((deal) => {
                const tlsCommentReplacmentBody: Record<number, string | null> =
                  {
                    1: deal.tlscsr_comment,
                    2: deal.ambassador,
                    3: handleDateFormat(deal.source_created_at),
                    4: deal.followup_date
                      ? handleDateFormat(deal.followup_date)
                      : '-',
                    5: deal.lead_source,
                  };

                return (
                  <tr key={deal.lead_source_id}>
                    <td style={{ whiteSpace: 'normal', minWidth: '160px' }}>
                      <b>{deal.name}</b>
                    </td>
                    <td>{deal.phone}</td>
                    <td>{deal.owner}</td>
                    <td>{deal.lead_expiration_date}</td>
                    <td>{deal.lead_expiration_status}</td>
                    <td>{deal.channel}</td>
                    <td>{deal.last_engagement_date}</td>
                    {activeTab === tabs.indirect && <td>{deal.broker_name}</td>}
                    <td>{tlsCommentReplacmentBody[activeTab]}</td>
                    <td style={{ minWidth: '120px' }}>{deal.sales_feedback}</td>
                    <td>
                      <div className={styles['last-column']}>
                        <Link
                          to={ROUTES['LeadCreatePipelines'].getPath?.(
                            deal.lead_source_id
                          )}
                          state={{
                            newPipeline: true,
                          }}
                        >
                          {t('addPipeline')}
                        </Link>
                        <Link
                          className={styles['deal-details']}
                          to={ROUTES['LeadPipelines'].getPath?.(
                            deal.lead_source_id
                          )}
                        >
                          <span className="anchor">{t('leadDetails')}</span>
                          <img src={arrowIcon} alt="" role="presentation" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>{t('noLeadsToDisplay')}</p>
        )}
      </div>
      {!tableLoading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > filteredDeals.length
                ? filteredDeals.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{filteredDeals.length}</span>
            {''}
            {t('leads')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={filteredDeals.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyDeals;

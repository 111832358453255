export type MapToOption<T, K> = {
  label: keyof T;
  value: keyof K;
};

export function mapToOption<T, K extends { label: keyof T; value: keyof T }>(
  data: T[],
  mapping: K
): { label: T[K['label']]; value: T[K['value']] }[] {
  return data.map((item) => ({
    label: item[mapping.label],
    value: item[mapping.value],
  }));
}

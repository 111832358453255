import { QualifyCustomer as QualifyCustomerAPI } from '../api/qualify-customer';
import {
  QualifyCustomerResponse,
  QualifyCustomer as QualifyCustomerInterface,
} from '../definitions/interfaces/qualify-customer';
import { OptionValue } from '@orascom/api-interfaces';
import { mapToOption } from './options-maping';

export class QualifyCustomer {
  public static getCustomerNationalities(): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerNationalities()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'nationality_name',
          value: 'nationality_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerCities(): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerCities()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'city_name',
          value: 'city_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerCountries(): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerCountries()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'country_name',
          value: 'country_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerOccupations(): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerOccupations()
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'label',
          value: 'code',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static qualifyCustomer(
    formData: QualifyCustomerInterface
  ): Promise<QualifyCustomerResponse> {
    return QualifyCustomerAPI.qualifyCustomer(formData)
      .then((result) => {
        return Promise.resolve(result.data?.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static attachementCustomer(
    customerId: string,
    destination: string,
    file: File
  ): Promise<void> {
    return QualifyCustomerAPI.attachementCustomer(customerId, destination, file)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}

import { Payload } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { Sale as SaleAPI } from '../api/sales';
import {
  SaleSubmission,
  Sales as SalesInterface,
} from '../definitions/interfaces/sales.interface';

export class Sales {
  public static getSales(
    viewNumber: number
  ): Promise<Payload<SalesInterface[]>> {
    return SaleAPI.getSales(viewNumber)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static createSale(saleData: SaleSubmission, unit: string) {
    return SaleAPI.createSale(saleData, unit)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static updateSale(
    saleData: Partial<SaleSubmission>,
    saleId: string,
    unitId: string
  ) {
    return SaleAPI.updateSale(saleData, saleId, unitId)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static uploadAttachment(
    unitId: string,
    saleId: string,
    file: File
  ): Promise<void> {
    return SaleAPI.uploadAttachment(unitId, saleId, file)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}

export enum ReservationPaymentMethods {
  CASH = '127990000',
  BANK_TRANSFER = '127990001',
  CREDIT_DEBIT_CARD = '127990002',
  CHEQUE = '127990003',
  UNIT_TRANSFER = '127990004',
}

export const reservationPaymentOptions = [
  { label: 'Cash', value: ReservationPaymentMethods.CASH },
  { label: 'Bank Transer', value: ReservationPaymentMethods.BANK_TRANSFER },
  {
    label: 'Credit/Debit Card',
    value: ReservationPaymentMethods.CREDIT_DEBIT_CARD,
  },
  { label: 'Cheque', value: ReservationPaymentMethods.CHEQUE },
  { label: 'Unit Transfer', value: ReservationPaymentMethods.UNIT_TRANSFER },
];

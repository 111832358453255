// import { LoadingButton } from '@orascom/common-components';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import { LoadingButton, Spinner } from '@orascom/common-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useDownload from 'libs/utils/src/hooks/use-download';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ENDPOINTS } from '../../api/endpoints';
import { ROUTES } from '../../api/routes';
import downloadIconUrl from '../../assets/icons/download-2.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import { SaleSubmission } from '../../definitions/interfaces/sales.interface';
import { Sales } from '../../utils/sale';
import { useCreateSaleContext } from './create-sale-context';
import styles from './create-sale.module.scss';

const getFullUrl = (endpoint: string) => {
  return process.env['NX__SALESMAN_API_ENDPOINT'] + endpoint;
};

interface FormData {
  proceedInReservation?: boolean;
  reservationFormSigned?: boolean;
}
const SaleReservationForm = () => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [uploading, setUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [pendingUpdateSale, setPendingUpdateSale] = useState(false);
  const { t } = useTranslation();
  const { goNext, selectedUnit, saleId } = useCreateSaleContext();

  const { customerId, pipelineId } = useParams<{
    customerId: string;
    pipelineId: string;
  }>();

  const endpoint = ENDPOINTS.downloadSaleReservationForm.url(
    selectedUnit?.id,
    saleId
  );

  const { isDownloading, triggerDownload } = useDownload({
    fullURL: getFullUrl(endpoint),
    method: 'POST',
    filename: 'reservation-form',
    extension: 'pdf',
    body: JSON.stringify({
      customer_source_id: customerId,
    }),
  });

  const fileName = selectedFile?.name;

  const handleUploadSignedReservationForm = async (file: File) => {
    if (!saleId || !selectedUnit?.id || uploading) return;
    setUploading(true);

    try {
      await Sales.uploadAttachment(
        selectedUnit.id.toString(),
        saleId.toString(),
        file
      );
      toast.success('File uploaded successfully');
      setFileUploaded(true);
    } catch (error) {
      toast.error('Error uploading file:');
    } finally {
      setUploading(false);
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(() => file);
      handleUploadSignedReservationForm(file);
    }
  };

  const submitHandler = (values: FormData) => {
    if (!saleId || !selectedUnit?.id || pendingUpdateSale || !fileUploaded)
      return;

    setPendingUpdateSale(true);

    const data: Partial<SaleSubmission> = {
      proceed_in_reservation: values.proceedInReservation ?? false,
      reservation_form_signed: values.reservationFormSigned ?? false,
      customer_source_id: customerId,
      pipeline_source_id: pipelineId,
    };

    Sales.updateSale(data, saleId, selectedUnit.id.toString())
      .then(() => {
        goNext();
        toast.success('Sale created successfully');
      })
      .catch(() => toast.error('Failed to update sale'))
      .finally(() => setPendingUpdateSale(false));
  };

  const schema = yup.object().shape({
    proceedInReservation: yup.boolean().test({
      name: 'proceedInReservation',
      message: t('proceedInReservationIsRequired'),
      test: (value) => !!value,
    }),
    reservationFormSigned: yup.boolean().test({
      name: 'reservationFormSigned',
      message: t('reservationFormSignedIsRequired'),
      test: (value) => !!value,
    }),
  });

  return (
    <div className={styles['reservation-wrapper']}>
      <div>
        <h2>{t('reservationForm')}</h2>
        <LoadingButton
          className={styles['download-btn']}
          loading={isDownloading}
          onClick={() => triggerDownload()}
        >
          <span className={styles['download-content']}>
            <img
              src={downloadIconUrl}
              alt="download"
              className={styles['download-icon']}
            />
            {t('downloadReservationForm')}
          </span>
        </LoadingButton>

        <Formik
          initialValues={{
            proceedInReservation: false,
            reservationFormSigned: false,
          }}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {({ submitCount }) => {
            return (
              <Form>
                <div
                  className={`${
                    uploading ? styles['uploading'] : ''
                  } input-wrapper`}
                >
                  <label>{t('uploadReservationForm')}*</label>
                  <label
                    htmlFor="file-upload"
                    className={styles['file-upload']}
                  >
                    {fileName?.length ? fileName : t('selectFilePlaceholder')}
                    {uploading ? (
                      <div className={styles['upload-spinner']}>
                        <Spinner />
                      </div>
                    ) : (
                      <span className={styles['upload']}>
                        <img src={uploadIcon} alt="" role="presentation" />
                      </span>
                    )}
                  </label>
                  <input
                    name="file-upload"
                    id="file-upload"
                    disabled={uploading}
                    type="file"
                    accept=".pdf,.docx"
                    placeholder={t('selectFilePlaceholder')}
                    onChange={onFileChange}
                  />
                  {submitCount > 0 && !fileUploaded && (
                    <p className="form__error">
                      {t('reservationFormIsRequired')}
                    </p>
                  )}
                </div>
                <div className={styles['checkbox-wrapper']}>
                  <Field
                    type="checkbox"
                    id="proceedInReservation"
                    name="proceedInReservation"
                  />

                  <label htmlFor="proceedInReservation">
                    {t('proceedInReservation')}
                  </label>
                </div>
                <ErrorMessage
                  component="p"
                  className="form__error"
                  name="proceedInReservation"
                />

                <div className={styles['checkbox-wrapper']}>
                  <Field
                    type="checkbox"
                    id="reservationFormSigned"
                    name="reservationFormSigned"
                  />

                  <label htmlFor="reservationFormSigned">
                    {t('reservationFormSigned')}
                  </label>
                </div>
                <ErrorMessage
                  component="p"
                  className="form__error"
                  name="reservationFormSigned"
                />
                <div className={styles['form-actions']}>
                  <LoadingButton
                    type="submit"
                    className="btn btn--navy"
                    loading={pendingUpdateSale}
                    disabled={uploading}
                  >
                    {t('createSale')}
                  </LoadingButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className={styles['unit']}>
        <p>{t('selectedUnit')}</p>
        {selectedUnit && (
          <PropertyCard
            unit={selectedUnit}
            portal="sales-man"
            handleRemove={undefined}
            listingView="grid"
            unitDetailsPath={ROUTES['UnitDetails'].getPath}
            hideCompare
            openDetailsInNewTab
          />
        )}
      </div>
    </div>
  );
};

export default SaleReservationForm;

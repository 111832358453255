import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { DisqualifyReason } from '../../definitions/interfaces/lead-stages.interface';
import { Deal } from '../../utils/deal.utils';
import styles from './disqualify-lead.module.scss';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingButton } from '@orascom/common-components';

export function DisqualifyLead() {
  const { t } = useTranslation();
  const { leadId } = useParams<{ leadId: string }>();
  const [pendingDisqualify, setPendingDisqualify] = useState(false);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    disqualifyReason: yup.string().label(t('disqualifyReason')).required(),
  });
  type Values = yup.InferType<typeof schema>;

  const handleSubmit = async (values: Values) => {
    if (!leadId) return;

    setPendingDisqualify(true);
    try {
      await Deal.disqualifyLead(leadId, {
        status_reason: +values.disqualifyReason,
      });
      toast.success('Lead disqualified successfully');
      navigate(ROUTES['MyDeals'].path);
    } catch {
      toast.error('Error disqualifying lead');
    } finally {
      setPendingDisqualify(false);
    }
  };

  const formFields = [
    {
      label: t('blacklisted'),
      value: DisqualifyReason.BLACKLIST,
    },
    {
      label: t('clientNotInterested'),
      value: DisqualifyReason.NO_LONGER_INTERTRESTED,
    },
    {
      label: t('opportunityLost'),
      value: DisqualifyReason.LOST,
    },
    {
      label: t('cannotContactClient'),
      value: DisqualifyReason.CANNOT_CONTACT,
    },
    {
      label: t('canceledByClient'),
      value: DisqualifyReason.CANCELED,
    },
  ];

  return (
    <div className={styles['wrapper']}>
      <Link
        to={ROUTES['LeadPipelines'].getPath?.(leadId)}
        className={styles['back-anchor']}
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('disqualifyLead')}</h1>
      <p className={styles['subtitle']}>
        Why did you decide to cancel this deal?
      </p>

      <Formik
        initialValues={
          {
            disqualifyReason: undefined as string | undefined,
          } as Values
        }
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <div
              className={styles['radio-group']}
              role="group"
              aria-labelledby="disqualify-reason-group"
            >
              {formFields.map(({ label, value }) => (
                <label key={value}>
                  <Field
                    type="radio"
                    name="disqualifyReason"
                    value={value.toString()}
                  />
                  <span className={styles['checkmark-box']}>
                    {+values.disqualifyReason === value && (
                      <span className={styles['checkmark']} />
                    )}
                  </span>
                  {label}
                </label>
              ))}
            </div>
            <ErrorMessage
              component="div"
              className="form__error"
              name="disqualifyReason"
            />
            <div className={styles['btns-wrapper']}>
              <LoadingButton
                type="submit"
                loading={pendingDisqualify}
                className={styles['disqualify-btn']}
                disabled={pendingDisqualify}
              >
                {t('disqualifyLead')}
              </LoadingButton>
              <button
                type="button"
                className={styles['cancel-btn']}
                onClick={() =>
                  navigate(ROUTES['LeadPipelines'].getPath?.(leadId))
                }
              >
                {t('cancel')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DisqualifyLead;

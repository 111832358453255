import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  Sales as SalesInterface,
  SaleSubmission,
} from '../definitions/interfaces/sales.interface';
import { getFileExtension } from '../utils/getFileExtension';

export class Sale {
  public static getSales(
    viewNumber: number
  ): Promise<Payload<Payload<SalesInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getSales.url(
        viewNumber
      )}`,
      {
        method: ENDPOINTS.getSales.method,
      }
    );
  }

  public static createSale(
    saleData: SaleSubmission,
    unit: string
  ): Promise<Payload<Payload<{ sale_source_id: string }>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createSale.url(
        unit
      )}`,
      {
        method: ENDPOINTS.createSale.method,
        body: JSON.stringify(saleData),
      }
    );
  }

  public static updateSale(
    saleData: Partial<SaleSubmission>,
    saleId: string,
    unitId: string
  ): Promise<Payload<Payload<void>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.updateSale.url(
        saleId,
        unitId
      )}`,
      {
        method: ENDPOINTS.updateSale.method,
        body: JSON.stringify(saleData),
      }
    );
  }

  public static uploadAttachment(
    unitId: string,
    saleId: string,
    file: File
  ): Promise<Payload<void>> {
    const fileExtension = getFileExtension(file.name);
    const newFileName = `sale-attachment.${fileExtension}`;

    // backend has some limitations on file names, so to be sure we are safe
    const renamedFile = new File(
      [file],
      newFileName,
      { type: file.type } // Preserve MIME type
    );

    const formData = new FormData();
    formData.append('attachment', renamedFile);
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.uploadSaleAttachment.url(unitId, saleId)}`,
      {
        method: ENDPOINTS.attachementCustomer.method,
        body: formData,
      },
      null
    );
  }
}

import { useState } from 'react';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import DealObjection from '../deal-objection/deal-objection';
import ScheduledMeetings from '../deal-scheduled-meetings/deal-scheduled-meetings';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import { InitialMeetingData } from '../initial-meeting/initial-meeting';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import styles from './lead-stages.module.scss';

function SalesPitchStage() {
  const [refetchActivityLog, setRefetchActivityLog] = useState(true);

  const { selectedPipeline } = useUserPipelineContext();

  const refetchLogs = () => setRefetchActivityLog(!refetchActivityLog);

  // it's not possiable to have a deal without a pipeline
  if (!selectedPipeline) return;

  return (
    <div className={styles['discovery-sections']}>
      <ScheduledMeetings
        selectedPipeline={selectedPipeline}
        dealStatus={PipelineStageEnum.SALES_PITCH}
      />
      <hr className={styles['line']} />
      <DealObjection />
      <hr className={styles['line']} />
      <InitialMeetingData
        initialMeetingDate={selectedPipeline.initial_meeting_date}
      />
      <hr className={styles['line']} />
      <SubmitFeedback
        refetchLogs={refetchLogs}
        dealStatus={PipelineStageEnum.SALES_PITCH}
      />
      <hr className={styles['line']} />
      <LeadActivityLog
        pipelineSourceId={selectedPipeline.pipeline_source_id}
        refetchActivityLog={refetchActivityLog}
      />
    </div>
  );
}

export default SalesPitchStage;

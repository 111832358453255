import { conditionalFields } from './../../utils/deal.utils';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useDiscoverySchema = () => {
  const { t } = useTranslation();

  const discoverySchema = yup.object({
    pipelineType: yup
      .number()
      .label(t('pipelineType'))
      .required()
      .moreThan(0, t('pleaseChooseAnOption'))
      .transform((value) => value ?? undefined),
    initalMeetingDate: yup
      .string()
      .label(t('initialMeetingDate'))
      .required()
      .transform((value) => value || undefined),
    destinationSlug: yup.string().label(t('destination')).nullable().required(),
    unitCategoryId: yup
      .number()
      .label(t('unitCategory'))
      .required()
      .transform((value) => value || undefined),
    feedbackId: yup
      .number()
      .label('Feedback')
      .required()
      .moreThan(0, t('pleaseChooseAnOption'))
      .transform((value) => {
        return value || undefined;
      }),

    ...conditionalFields,
  });

  return discoverySchema;
};

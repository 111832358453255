import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import DealClose from '../deal-close/deal-close';
import DealObjection from '../deal-objection/deal-objection';
import ScheduledMeetings from '../deal-scheduled-meetings/deal-scheduled-meetings';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import { InitialMeetingData } from '../initial-meeting/initial-meeting';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import styles from './lead-stages.module.scss';
import PendingReservation from './pending-reservation';

function ClosingStage() {
  const { t } = useTranslation();

  const { selectedPipeline, customerId, leadId, lead } =
    useUserPipelineContext();
  const [refetchActivityLog, setRefetchActivityLog] = useState(true);

  const closureDate = selectedPipeline?.closure_date;

  const isClosingPhase =
    selectedPipeline?.current_phase === PipelineStageEnum.DEAL_CLOSING;

  const isPendingReservation = isClosingPhase && Boolean(closureDate);

  const showDealClose = isClosingPhase && !isPendingReservation;

  // it's not possiable to have a deal without a pipeline
  if (!selectedPipeline) return;

  const isCustomer = !!customerId;
  const qualifiedLead = lead?.customer_source_id;

  const linkText = isCustomer
    ? t('createSale')
    : qualifiedLead
    ? t('customerDetails')
    : t('qualifyCustomer');

  const linkTo = isCustomer
    ? ROUTES['CreateSale']?.getPath?.(
        customerId,
        selectedPipeline.pipeline_source_id
      )
    : qualifiedLead
    ? ROUTES['CustomerPipelines']?.getPath?.(lead.customer_source_id)
    : ROUTES['QualifyCustomer'].getPath?.(
        selectedPipeline.destination_slug,
        leadId
      );

  return (
    <div className={styles['discovery-sections']}>
      {isPendingReservation && (
        <PendingReservation
          meetingDate={selectedPipeline?.closure_date}
          customerId={customerId}
          leadId={leadId}
          pipeline_source_id={selectedPipeline.pipeline_source_id}
          linkText={linkText}
          lintTo={linkTo}
        />
      )}
      {showDealClose && <DealClose />}
      <ScheduledMeetings
        selectedPipeline={selectedPipeline}
        dealStatus={PipelineStageEnum.DEAL_CLOSING}
      />
      <hr className={styles['line']} />
      <DealObjection />
      <hr className={styles['line']} />
      <InitialMeetingData
        initialMeetingDate={selectedPipeline.initial_meeting_date}
      />
      <hr className={styles['line']} />
      <SubmitFeedback
        refetchLogs={() => setRefetchActivityLog(!refetchActivityLog)}
        dealStatus={PipelineStageEnum.SALES_PITCH}
      />
      <hr className={styles['line']} />
      <LeadActivityLog
        pipelineSourceId={selectedPipeline.pipeline_source_id}
        refetchActivityLog={refetchActivityLog}
      />
    </div>
  );
}

export default ClosingStage;

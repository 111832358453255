import { SelectDropdown } from '@orascom/common-components';
import styles from './lead-feedback.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { LeadDiscoveryInput } from '../../definitions/interfaces/lead-stages.interface';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import {
  competitorAdvantageOptions,
  defineIssuesOptions,
  feedbackOptions,
  issuesOptions,
  leadDiscoveryFeedbackOptionsEnum,
  Deal as DealUtils,
  desiredProductOptions,
  concernOptions,
  DesiredProductOptionsEnum,
  ConcernOptionsEnum,
  DefineIssueValues,
  booleanDropdownOptions,
} from '../../utils/deal.utils';
import { addDays, format } from 'date-fns';
import Calendar from 'react-calendar';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import { OptionValue } from '@orascom/api-interfaces';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';

type Value = Date | null;

export interface LeadFeedbackProps {
  dealStatus: PipelineStageEnum;
  addFeedback?: boolean;
  setAddFeedback?: React.Dispatch<React.SetStateAction<boolean>>;
  pipelineFeedback?: string;
}
export function LeadFeedback({
  dealStatus,
  addFeedback,
  setAddFeedback,
  pipelineFeedback,
}: LeadFeedbackProps) {
  const { errors, touched, setFieldValue, values } =
    useFormikContext<LeadDiscoveryInput>();
  const { t } = useTranslation();
  const [competitions, setCompetitions] = useState<OptionValue[]>([]);
  const [currencies, setCurrencies] = useState<OptionValue[]>([]);

  const [desiredProduct, setDesiredProduct] = useState<OptionValue>();
  const [concern, setConcern] = useState<OptionValue>();

  const [, setFutureOpportunity] = useState({
    label: '',
    value: 0,
  });
  const [futureBudgetِِِِِAvailability, setFutureBudgetAvailability] = useState(
    {
      label: '',
      value: 0,
    }
  );
  const [, setIssuesValue] = useState<OptionValue>();
  const [, setCompetitorAdvantage] = useState({
    label: '',
    value: 0,
  });
  const [defineIssue, setDefineIssue] = useState<OptionValue>();
  const [selectedDate, setSelectedDate] = useState<Value>();
  const [selectedExpectedDate, setSelectedExpectedDate] = useState<Value>();

  const dialogRefFollowUp = useRef<HTMLDialogElement | null>(null);

  const dialogRefExpectedDate = useRef<HTMLDialogElement | null>(null);

  const toggleDialog = () => {
    if (dialogRefFollowUp.current) {
      if (dialogRefFollowUp.current.open) {
        dialogRefFollowUp.current.close();
      } else {
        dialogRefFollowUp.current.show();
      }
    }
  };

  const toggleExpectedDialog = () => {
    if (dialogRefExpectedDate.current) {
      if (dialogRefExpectedDate.current.open) {
        dialogRefExpectedDate.current.close();
      } else {
        dialogRefExpectedDate.current.show();
      }
    }
  };
  const selectedFeedbackValue = values.feedbackId;

  useEffect(() => {
    DealUtils.getCompetitionList()
      .then((res) => {
        setCompetitions(res);
      })
      .catch((err) => {
        console.log(err);
      });

    DealUtils.getCurrenciesList()
      .then((res) => {
        setCurrencies(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS
    ) {
      setFieldValue('moreDetails', '');
      setFieldValue('issue', null);
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.COMPETITION
    ) {
      setFieldValue('lostCompetitorSourceId', '');
      setFieldValue('furtherDetails', '');
      setFieldValue('competitorAdvantage', '');
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN
    ) {
      setFieldValue('expectedBudget', '');
      setFieldValue('currency', '');
      setFieldValue('futureBudgetAvailability', null);
      setFieldValue('futureOpportunity', null);
      setFieldValue('excpectedDate', '');
      setSelectedExpectedDate(null);
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN
    ) {
      setFieldValue('defineIssue', null);
      setFieldValue('futureOpportunity', null);
      setFieldValue('desiredProduct', null);
      setFieldValue('concern', null);
    }

    if (selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.OTHERS) {
      setFieldValue('clarifyReasons', '');
    }
  }, [selectedFeedbackValue]);

  const showAddExtraFeedback =
    (dealStatus === PipelineStageEnum.SALES_PITCH ||
      dealStatus === PipelineStageEnum.DEAL_CLOSING) &&
    addFeedback;
  const showAddFeedback =
    dealStatus === PipelineStageEnum.LEAD_DISCOVERY || showAddExtraFeedback;
  return (
    <div className={styles['lead-feedback']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('leadFeedback')}</p>
        {(dealStatus === PipelineStageEnum.SALES_PITCH ||
          dealStatus === PipelineStageEnum.DEAL_CLOSING) && (
          <button
            type="button"
            onClick={() => setAddFeedback?.(true)}
            className={styles['add-btn']}
          >
            {t('addAnotherFeedback')}
          </button>
        )}
      </div>
      {(dealStatus === PipelineStageEnum.SALES_PITCH ||
        dealStatus === PipelineStageEnum.DEAL_CLOSING) &&
        pipelineFeedback && (
          <div className={styles['pipeline-feedback']}>
            <p>Feedback:</p>
            <p>{pipelineFeedback}</p>
          </div>
        )}

      {showAddFeedback && (
        <div className={styles['feedback-input']}>
          <div>
            <label>{t('feedback')}</label>
            <SelectDropdown
              name="feedbackId"
              options={feedbackOptions}
              placeholder={t('selectPlaceholder')}
              className={`${styles['select']}`}
              invalid={touched.feedbackId && !!errors.feedbackId}
              onChange={(value) => {
                setFieldValue('feedbackId', value?.value);
              }}
              selectedOption={feedbackOptions.find((option) => {
                return option.value === values.feedbackId;
              })}
            />
            <ErrorMessage
              name="feedbackId"
              component="div"
              className="form__error"
            />
          </div>
          <div>
            <label htmlFor="salesComment">
              {t('yourFeedback')} ({t('optional')})
            </label>
            <Field
              className={styles['feedback-text']}
              type="text"
              as="textarea"
              name="salesComment"
              placeholder={t('feedbackPlaceholder')}
            />
          </div>
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.FOLLOW_UP && (
            <div>
              <label htmlFor="followup_date">{t('followUpDate')}</label>
              <div className={styles['feedback-modal-wrapper']}>
                <button
                  type="button"
                  onClick={toggleDialog}
                  className={styles['btn-outline']}
                >
                  <p>
                    {selectedDate
                      ? format(new Date(selectedDate), 'yyyy-MM-dd')
                      : t('selectDate')}
                  </p>
                  <img
                    style={{ height: 'auto', rotate: '-90deg' }}
                    src={ChevronLeft}
                    alt=""
                    role="presentation"
                  />
                </button>
                <dialog
                  ref={dialogRefFollowUp}
                  className={styles['feedback-modal']}
                >
                  <Calendar
                    value={selectedDate}
                    // tommorow
                    minDate={addDays(new Date(), 1)}
                    onChange={(date) => {
                      setSelectedDate(date as Value);
                      setFieldValue(
                        'followUpdate',
                        format(date as Date, 'yyyy-MM-dd')
                      );
                      toggleDialog();
                    }}
                    view={'month'}
                    prevLabel={
                      <img
                        style={{ height: 'auto' }}
                        src={LeftArrowIcon}
                        alt=""
                        role="presentation"
                      />
                    }
                    nextLabel={
                      <img
                        style={{ height: 'auto' }}
                        src={RightArrowIcon}
                        alt=""
                        role="presentation"
                      />
                    }
                  />
                </dialog>
                <ErrorMessage
                  name="followUpdate"
                  component="div"
                  className="form__error"
                />
              </div>
            </div>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS && (
            <>
              <div>
                <label htmlFor="issue">{t('issue')}</label>
                <SelectDropdown
                  name="issue"
                  options={issuesOptions}
                  placeholder={t('selectPlaceholder')}
                  className={`${styles['select']}`}
                  invalid={touched.issue && !!errors.issue}
                  onChange={(value) => {
                    setIssuesValue({
                      label: value!.label,
                      value: value!.value as number,
                    });
                    setFieldValue('issue', value?.value);
                  }}
                  selectedOption={issuesOptions.find((option) => {
                    return option.value === values.issue;
                  })}
                />
                <ErrorMessage
                  name="issue"
                  component="div"
                  className="form__error"
                />
              </div>
              <div>
                <label htmlFor="moreDetails">{t('moreDetails')}</label>
                <Field
                  className={styles['feedback-text']}
                  name="moreDetails"
                  type="text"
                  as="textarea"
                  placeholder={t('enterDetails')}
                />
                <ErrorMessage
                  name="moreDetails"
                  component="div"
                  className="form__error"
                />
              </div>
            </>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.COMPETITION && (
            <>
              <div>
                <label htmlFor="lostCompetitorSourceId">
                  {t('lostcompetitorid')}
                </label>
                <SelectDropdown
                  name="lostCompetitorSourceId"
                  options={competitions}
                  placeholder={t('selectPlaceholder')}
                  className={`${styles['select']}`}
                  invalid={
                    touched.lostCompetitorSourceId &&
                    !!errors.lostCompetitorSourceId
                  }
                  onChange={(value) => {
                    setFieldValue('lostCompetitorSourceId', value?.value);
                  }}
                  selectedOption={competitions.find((option) => {
                    return option.value === values.lostCompetitorSourceId;
                  })}
                />
                <ErrorMessage
                  name="lostCompetitorSourceId"
                  component="div"
                  className="form__error"
                />
              </div>
              <div>
                <label htmlFor="furtherDetails">{t('furtherDetails')}</label>
                <Field
                  name="furtherDetails"
                  className={styles['feedback-text']}
                  type="text"
                  as="textarea"
                  placeholder={t('addFurtherDetails')}
                />
                <ErrorMessage
                  name="furtherDetails"
                  component="div"
                  className="form__error"
                />
              </div>
              <div>
                <label htmlFor="competitorAdvantage">
                  {t('competitorAdvantage')}
                </label>
                <SelectDropdown
                  name="competitorAdvantage"
                  options={competitorAdvantageOptions}
                  placeholder={t('selectPlaceholder')}
                  className={`${styles['select']} ${
                    errors?.competitorAdvantage && touched?.competitorAdvantage
                      ? 'error'
                      : ''
                  }`}
                  invalid={
                    touched.competitorAdvantage && !!errors.competitorAdvantage
                  }
                  onChange={(value) => {
                    setCompetitorAdvantage({
                      label: value!.label,
                      value: value!.value as number,
                    });
                    setFieldValue('competitorAdvantage', value?.value);
                  }}
                  selectedOption={competitorAdvantageOptions.find((option) => {
                    return option.value === Number(values.competitorAdvantage);
                  })}
                />
                <ErrorMessage
                  name="competitorAdvantage"
                  component="div"
                  className="form__error"
                />
              </div>
            </>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN && (
            <>
              <div>
                <label htmlFor="expectedBudget">{t('expectedbudget')}</label>
                <Field
                  name="expectedBudget"
                  className={styles['feedback-text-small']}
                  type="number"
                  placeholder={t('addExcpectedBudget')}
                />
                <ErrorMessage
                  name="expectedBudget"
                  component="div"
                  className="form__error"
                />
              </div>
              <div>
                <label htmlFor="currency">{t('currency')}</label>
                <SelectDropdown
                  name="currency"
                  options={currencies}
                  placeholder={t('selectPlaceholder')}
                  className={`${styles['select']}`}
                  invalid={touched.currency && !!errors.currency}
                  onChange={(value) => {
                    setFieldValue('currency', value?.value);
                  }}
                  selectedOption={currencies.find((option) => {
                    return option.value === values.currency;
                  })}
                />
                <ErrorMessage
                  name="currency"
                  component="div"
                  className="form__error"
                />
              </div>
              <div>
                <label htmlFor="futureBudgetAvailability">
                  {t('futurebudgetavailability')}
                </label>
                <SelectDropdown
                  name="futureBudgetAvailability"
                  options={booleanDropdownOptions}
                  placeholder={t('yes/no')}
                  className={styles['select']}
                  invalid={
                    touched.futureBudgetAvailability &&
                    !!errors.futureBudgetAvailability
                  }
                  onChange={(value) => {
                    setFutureBudgetAvailability({
                      label: value!.label,
                      value: value!.value as number,
                    });
                    setFieldValue('futureBudgetAvailability', value?.value);
                  }}
                  selectedOption={booleanDropdownOptions.find((option) => {
                    return (
                      option.value ===
                        Number(values.futureBudgetAvailability) &&
                      values.futureBudgetAvailability
                    );
                  })}
                />
                <ErrorMessage
                  name="futureBudgetAvailability"
                  component="div"
                  className="form__error"
                />
              </div>
            </>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN &&
          futureBudgetِِِِِAvailability.value ? (
            <div>
              <label htmlFor="expectedDate">{t('expectedDate')}</label>
              <div className={styles['feedback-modal-wrapper']}>
                <button
                  type="button"
                  onClick={toggleExpectedDialog}
                  className={styles['btn-outline']}
                >
                  <p>
                    {selectedExpectedDate
                      ? format(new Date(selectedExpectedDate), 'yyyy-MM-dd')
                      : t('selectDate')}
                  </p>
                  <img
                    style={{ height: 'auto', rotate: '-90deg' }}
                    src={ChevronLeft}
                    alt=""
                    role="presentation"
                  />
                </button>
                <dialog
                  ref={dialogRefExpectedDate}
                  className={styles['feedback-modal']}
                >
                  <Calendar
                    value={selectedExpectedDate}
                    minDate={new Date()}
                    onChange={(date) => {
                      setSelectedExpectedDate(date as Value);
                      setFieldValue(
                        'expectedDate',
                        format(date as Date, 'yyyy-MM-dd')
                      );
                      toggleExpectedDialog();
                    }}
                    view={'month'}
                    prevLabel={
                      <img
                        style={{ height: 'auto' }}
                        src={LeftArrowIcon}
                        alt=""
                        role="presentation"
                      />
                    }
                    nextLabel={
                      <img
                        style={{ height: 'auto' }}
                        src={RightArrowIcon}
                        alt=""
                        role="presentation"
                      />
                    }
                  />
                </dialog>
                {touched?.expectedDate && errors?.expectedDate && (
                  <div className="form__error">{errors.expectedDate}</div>
                )}
              </div>
            </div>
          ) : null}
          {(selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN ||
            selectedFeedbackValue ===
              leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN) && (
            <div>
              <label htmlFor="futureOpportunity">
                {t('futureopportunity')}
              </label>
              <SelectDropdown
                name="futureOpportunity"
                options={booleanDropdownOptions}
                placeholder={t('yes/no')}
                className={styles['select']}
                invalid={
                  touched.futureOpportunity && !!errors.futureOpportunity
                }
                onChange={(value) => {
                  setFutureOpportunity({
                    label: value!.label,
                    value: value!.value as number,
                  });
                  setFieldValue('futureOpportunity', value?.value);
                }}
                selectedOption={booleanDropdownOptions.find((option) => {
                  return option.value === values.futureOpportunity;
                })}
              />
              <ErrorMessage
                name="futureOpportunity"
                component="div"
                className="form__error"
              />
            </div>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN && (
            <div>
              <label htmlFor="defineIssue">{t('defineIssue')}</label>
              <SelectDropdown
                name="defineIssue"
                options={defineIssuesOptions}
                placeholder={t('selectPlaceholder')}
                className={styles['select']}
                onChange={(value) => {
                  setDefineIssue({
                    label: value!.label,
                    value: value!.value as number,
                  });
                  setFieldValue('concern', null);
                  setFieldValue('desiredProduct', null);
                  setFieldValue('defineIssue', value?.value);
                }}
                selectedOption={defineIssuesOptions.find((option) => {
                  return option.value === Number(values.defineIssue);
                })}
              />
              <ErrorMessage
                name="defineIssue"
                component="div"
                className="form__error"
              />
            </div>
          )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
            defineIssue?.value === DefineIssueValues.AVAILABILTY && (
              <div>
                <label htmlFor="desiredProduct">{t('desiredProduct')}</label>
                <SelectDropdown
                  name="desiredProduct"
                  options={desiredProductOptions}
                  placeholder={t('selectPlaceholder')}
                  className={styles['select']}
                  onChange={(value) => {
                    setDesiredProduct({
                      label: value!.label,
                      value: value!.value as number,
                    });
                    setFieldValue('desiredProduct', value?.value);
                  }}
                  invalid={touched.desiredProduct && !!errors.desiredProduct}
                  selectedOption={desiredProductOptions.find((option) => {
                    return option.value === values.desiredProduct;
                  })}
                />
                <ErrorMessage
                  name="desiredProduct"
                  component="div"
                  className="form__error"
                />
              </div>
            )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
            defineIssue?.value === DefineIssueValues.CONCERN && (
              <div>
                <label htmlFor="concern">{t('concern')}</label>
                <SelectDropdown
                  name="concern"
                  options={concernOptions}
                  placeholder={t('selectPlaceholder')}
                  className={styles['select']}
                  onChange={(value) => {
                    setConcern({
                      label: value!.label,
                      value: value!.value as number,
                    });
                    setFieldValue('concern', value?.value);
                  }}
                  invalid={touched.concern && !!errors.concern}
                  selectedOption={concernOptions.find((option) => {
                    return option.value === values.concern;
                  })}
                />
                <ErrorMessage
                  name="concern"
                  component="div"
                  className="form__error"
                />
              </div>
            )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN &&
            ((desiredProduct?.value === DesiredProductOptionsEnum.OTHER &&
              defineIssue?.value === DefineIssueValues.AVAILABILTY) ||
              (defineIssue?.value === DefineIssueValues.CONCERN &&
                concern?.value === ConcernOptionsEnum.OTHERS)) && (
              <div>
                <label htmlFor="productDetails">{t('productDetails')}</label>
                <Field
                  name="productDetails"
                  className={styles['feedback-text']}
                  type="text"
                  as="textarea"
                  placeholder={t('productDetailsPlaceholder')}
                />
                <ErrorMessage
                  name="productDetails"
                  component="div"
                  className="form__error"
                />
              </div>
            )}
          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.OTHERS && (
            <div>
              <label htmlFor="clarifyReasons">{t('clarifyreasons')}</label>
              <Field
                name="clarifyReasons"
                className={styles['feedback-text']}
                type="text"
                as="textarea"
                placeholder={t('feedbackPlaceholder')}
              />
              <ErrorMessage
                name="clarifyReasons"
                component="div"
                className="form__error"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default LeadFeedback;

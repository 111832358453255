import styles from './select-sale-payment.module.scss';

interface Props {
  label: string;
  result: string;
  resultType?: 'discount' | 'bold' | 'normal';
}

export default function UnitDetailInfo({ label, result, resultType }: Props) {
  return (
    <p>
      {label}
      <span
        className={
          styles[
            resultType === 'discount'
              ? 'unit__discount'
              : resultType === 'bold'
              ? 'unit__bold'
              : ''
          ]
        }
      >
        {result}
      </span>
    </p>
  );
}
